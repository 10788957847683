import { useContext, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
    LinearProgress,
    Box,
    Button,
    Stack,
    Typography,
    CircularProgress,
} from "@mui/material";
import { paths, dynamicPathAffixes, numbers } from "./Constants.js";
import {
    asyncAuth,
    dynamizeURL,
    isAdmin,
    listAllOrgs,
    logout,
    refreshPage,
    stripAllOrgsDownAsAdmin,
    orgContextIsValid,
    infoNecessaryToReverseDynamizePage,
    getIndexOfOrgWithID,
    reverseDynamizeAKAHydratePageFromURL,
} from "./helperFuncs.js";
import Navbar from "./Navbar.js";
import OrgBar, { OrgContext } from "./organization.js";
import { AllWrapper, NavWrapper, OrgWrapper } from "./styles.js";
import useWindowDimensions, { isMobile } from "./windowDimensions.js";

const Dashboard = () => {
    const { org, setOrg, allOrgs, setOrgs, user, setUser } =
        useContext(OrgContext);

    const { height, width } = useWindowDimensions();

    const loc = useLocation();

    let navigate = useNavigate();
    useEffect(() => {
        asyncAuth()
            .then(async (authRes) => {
                console.log("authenticated! loading dashboard...");
            })
            .catch((err) => {
                console.log("not authenticated! redirecting to login...");
                console.log(err);
                navigate(paths.LOGIN);
            });
    }, []);

    return (
        <OrgWrapper>
            <OrgBar />
            {orgContextIsValid(org, allOrgs, user) ? (
                <NavWrapper>
                    <Navbar />
                    <AllWrapper>
                        <Box
                            sx={{
                                paddingLeft: "24px",
                            }}
                            maxWidth='100vw'
                        >
                            <Typography
                                component='h1'
                                variant={isMobile(width) ? "h3" : "h2"}
                                align='left'
                                color='text.primary'
                                gutterBottom
                            >
                                Welcome back,{" "}
                                {user.first_name + " " + user.last_name}
                            </Typography>
                            <Typography
                                variant={isMobile(width) ? "h6" : "h5"}
                                align='left'
                                color='text.secondary'
                                paragraph
                            >
                                Viewing <b>{allOrgs[org].name}</b> as{" "}
                                <b>{allOrgs[org].role}</b>
                            </Typography>
                            <Stack
                                sx={{ pt: 4 }}
                                direction='row'
                                spacing={2}
                                justifyContent='le'
                            >
                                <Button
                                    variant='contained'
                                    onClick={() =>
                                        navigate(
                                            dynamizeURL(paths.BROWSER, org)
                                        )
                                    }
                                >
                                    Browse{" "}
                                    {isMobile(width) ? "All" : "All Images"}
                                </Button>
                                {isMobile(width) ||
                                !isAdmin(allOrgs, org, user.id) ? null : (
                                    <Button
                                        variant='outlined'
                                        onClick={() =>
                                            navigate(
                                                dynamizeURL(paths.UPLOAD, org)
                                            )
                                        }
                                    >
                                        Upload{" "}
                                        {isMobile(width) ? "New" : "New Images"}
                                    </Button>
                                )}
                            </Stack>
                        </Box>
                    </AllWrapper>
                </NavWrapper>
            ) : (
                <LinearProgress />
            )}
        </OrgWrapper>
    );
};

export default Dashboard;
