import * as React from "react";
import {
    Add,
    ArrowBack,
    ArrowForward,
    Close,
    Delete,
} from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Modal,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    remoteAdd,
    remoteDemote,
    remotePromote,
    remoteRemove,
} from "./helperFuncs.js";
import { ModalBody, ModalFooter, ModalHeader, modalStyle } from "./styles.js";
import { numbers } from "./Constants.js";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

function exclude(arr, item) {
    return arr.filter((i) => i !== item);
}

/**
 * Renders a transferrable list (https://mui.com/material-ui/react-transfer-list/)
 * @param {*} props should contain the following props:
 * @param {object[]} props.leftArr - the items in the left list, each element a user
 * @param {object[]} props.rightArr - the items in the right list, each element a user
 * @param {object[]} props.activeOrgID - the ID of the currently active org
 * @param {function} props.refresh - a callback to request a refresh of the props from origin
 * @returns the JSX to render
 */
const TransferList = (props) => {
    const [checked, setChecked] = React.useState([]);

    const [left, setLeft] = React.useState(props.leftArr);
    const [right, setRight] = React.useState(props.rightArr);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [addEmail, setAddEmail] = React.useState("");
    const [addAsAdmin, setAddAsAdmin] = React.useState(false);

    React.useEffect(() => {
        setLeft(props.leftArr);
        setRight(props.rightArr);
    }, [props.leftArr, props.rightArr]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handlePromote = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));

        for (const user of leftChecked) {
            remotePromote(user.id, props.activeOrgID);
        }

        // refresh after adding after a delay
        setTimeout(() => {
            props.refresh();
        }, numbers.REFRESH_DELAY);
    };

    const handleDemote = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));

        for (const user of rightChecked) {
            remoteDemote(user.id, props.activeOrgID);
        }

        // refresh after adding after a delay
        setTimeout(() => {
            props.refresh();
        }, numbers.REFRESH_DELAY);
    };

    const handleRemove = () => {
        setChecked([]);
        for (const user of checked) {
            setLeft(exclude(left, user));
            setRight(exclude(right, user));

            remoteRemove(user.id, props.activeOrgID, right.includes(user));
        }

        // refresh after adding after a delay
        setTimeout(() => {
            props.refresh();
        }, numbers.REFRESH_DELAY);
    };

    const handleAdd = () => {
        setAddModalOpen(true);
    };

    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={
                            numberOfChecked(items) === items.length &&
                            items.length !== 0
                        }
                        indeterminate={
                            numberOfChecked(items) !== items.length &&
                            numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            "aria-label": "all items selected",
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
                sx={{
                    width: 600,
                    height: 230,
                    bgcolor: "background.paper",
                    overflow: "auto",
                }}
                dense
                component='div'
                role='list'
            >
                {items.map((item) => {
                    const labelId = `transfer-list-all-item-${item.id}-label`;

                    return (
                        <ListItem
                            key={item.id}
                            role='listitem'
                            button
                            onClick={handleToggle(item)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(item) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        "aria-labelledby": labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={`${item.first_name} ${item.last_name}`}
                            />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    const closeModal = () => {
        setAddModalOpen(false);
        setAddEmail("");
        setAddAsAdmin(false);
    };

    const handleAddSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        remoteAdd(addEmail, props.activeOrgID, addAsAdmin);
        // refresh after adding after a delay
        setTimeout(() => {
            props.refresh();
        }, numbers.REFRESH_DELAY);
        closeModal();
    };

    return (
        <>
            <Modal
                open={addModalOpen}
                onClose={closeModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={modalStyle}>
                    <ModalHeader>
                        <Typography
                            id='modal-modal-title'
                            variant='h6'
                            component='h2'
                        >
                            Add a New Member
                        </Typography>
                        <IconButton
                            color='secondary'
                            aria-label='Close Modal'
                            onClick={closeModal}
                        >
                            <Close />
                        </IconButton>
                    </ModalHeader>
                    <ModalBody>
                        <TextField
                            id='standard-basic'
                            label='Email'
                            variant='standard'
                            value={addEmail}
                            onChange={(e) => setAddEmail(e.target.value)}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={addAsAdmin}
                                    onChange={(e) =>
                                        setAddAsAdmin(e.target.checked)
                                    }
                                />
                            }
                            label='Make this user an admin'
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='contained' onClick={handleAddSubmit}>
                            Submit
                        </Button>
                    </ModalFooter>
                </Box>
            </Modal>
            <Grid
                container
                spacing={2}
                sx={{
                    marginTop: "48px",
                }}
                justifyContent='left'
                alignItems='left'
            >
                <Grid item xs={6} md={6}>
                    {customList("Members", left)}
                </Grid>
                <Grid item>
                    <Grid container direction='column' alignItems='center'>
                        <Tooltip
                            title={"Promote selected users to admin"}
                            arrow
                            placement='left'
                        >
                            <IconButton
                                sx={{ my: 0.5 }}
                                variant='outlined'
                                size='small'
                                onClick={handlePromote}
                                disabled={leftChecked.length === 0}
                                aria-label='move selected right'
                            >
                                <ArrowForward />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={"Demote selected admins to member"}
                            arrow
                            placement='left'
                        >
                            <IconButton
                                sx={{ my: 0.5 }}
                                variant='outlined'
                                size='small'
                                onClick={handleDemote}
                                disabled={rightChecked.length === 0}
                                aria-label='move selected left'
                            >
                                <ArrowBack />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={"Remove selected from organization"}
                            arrow
                            placement='left'
                        >
                            <IconButton
                                sx={{ my: 0.5 }}
                                variant='outlined'
                                size='small'
                                onClick={handleRemove}
                                disabled={checked.length === 0}
                                aria-label='delete selected'
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={"Add a new member"}
                            arrow
                            placement='left'
                        >
                            <IconButton
                                sx={{ my: 0.5 }}
                                variant='outlined'
                                size='small'
                                onClick={handleAdd}
                                disabled={false}
                                aria-label='add new'
                            >
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                    {customList("Admins", right)}
                </Grid>
            </Grid>
        </>
    );
};

export default TransferList;
