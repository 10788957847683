import React, { useEffect, useState } from "react";
import {
    Alert,
    Box,
    Button,
    Container,
    LinearProgress,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import {
    asyncAuth,
    emailValidation,
    getKeyByValue,
    getTokenStrFromStorage,
} from "./helperFuncs.js";
import OrgBar from "./organization";
import { numbers, paths } from "./Constants.js";
import { AllWrapper, NavWrapper, OrgWrapper } from "./styles";

require("dotenv").config();

const Labels = {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    password: "Password",
};

const ChangeField = (props) => {
    const [value, setValue] = useState("");

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "15px",
                margin: "15px 0",
            }}
        >
            <TextField
                id="filled-basic"
                label={props.label}
                variant="filled"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                autoFocus
                placeholder={props.hidden ? "**********" : props.placeholder}
                type={props.hidden ? "password" : "text"}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    props.submit(value, props.label);
                }}
            >
                Submit
            </Button>
        </Box>
    );
};

const Account = (props) => {
    const [authInfo, setAuthInfo] = useState(null);

    const [showEmailInvalid, setShowEmailInvalid] = useState(false);

    const submitToAPI = (newArg, param) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());
        myHeaders.append("Content-Type", "application/json");

        // check validation if email

        if (param === Labels.email) {
            if (!emailValidation(newArg)) {
                setShowEmailInvalid(true);
                setTimeout(function () {
                    setShowEmailInvalid(false);
                }, numbers.ALERT_TIMEOUT); // remove the alert after 6 seconds
                return;
            }
        }

        // if valid, send to API

        var raw = `{"${getKeyByValue(Labels, param)}": "${newArg}"}`;

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL + "/auth/user_info/",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        asyncAuth()
            .then((authRes) => {
                console.log(
                    "already authenticated! staying on account page..."
                );
                setAuthInfo(authRes);
            })
            .catch((err) => {
                console.log("not authenticated! redirecting to login...");
                props.goTo(paths.LOGIN);
            });
    }, []);

    if (authInfo === null) {
        return (
            <>
                <LinearProgress />
            </>
        );
    }

    return (
        <OrgWrapper>
            <OrgBar nonOrg />
            <Snackbar
                open={showEmailInvalid}
                autoHideDuration={numbers.ALERT_TIMEOUT}
            >
                <Alert severity="error" sx={{ width: "100%" }}>
                    Invalid Email
                </Alert>
            </Snackbar>
            <NavWrapper>
                <AllWrapper>
                    <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
                        <Typography
                            component="h1"
                            variant="h2"
                            align="left"
                            color="text.primary"
                            gutterBottom
                        >
                            Account Settings
                        </Typography>
                        <Typography
                            variant="h5"
                            align="left"
                            color="text.secondary"
                            paragraph
                        >
                            Currently signed in as{" "}
                            <b>
                                {authInfo.first_name} {authInfo.last_name}
                            </b>
                            <br />
                            with email <b>{authInfo.email}</b>
                        </Typography>

                        <br />

                        <ChangeField
                            label={Labels.first_name}
                            placeholder={authInfo.first_name}
                            submit={submitToAPI}
                        />
                        <ChangeField
                            label={Labels.last_name}
                            placeholder={authInfo.last_name}
                            submit={submitToAPI}
                        />
                        <ChangeField
                            label={Labels.email}
                            placeholder={authInfo.email}
                            submit={submitToAPI}
                        />
                        <ChangeField
                            label={Labels.password}
                            placeholder={authInfo.password}
                            submit={submitToAPI}
                            hidden={true}
                        />
                    </Container>
                </AllWrapper>
            </NavWrapper>
        </OrgWrapper>
    );
};

export default Account;
