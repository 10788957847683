// This file contains styles shared in at least two places (or related to each other).

import styled from "styled-components";
import { MOBILE_WIDTH } from "./windowDimensions";
import { customVisuals } from "./Constants";

// for all the content within a page
export const AllWrapper = styled.div`
    @media (min-width: ${MOBILE_WIDTH}px) {
        padding: 64px 0 0 50px;
    }
    @media (max-width: ${MOBILE_WIDTH}px) {
        padding: 36px 16px 0 16px;
    }
    margin-top: ${customVisuals.navbarHeight}px;
    min-height: calc(100vh - ${customVisuals.navbarHeight}px);
`;

// for positioning the orgbar in relation to the content
export const OrgWrapper = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
`;

// for positioning the navbar in relation to the content
export const NavWrapper = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${MOBILE_WIDTH}px) {
        margin-left: ${customVisuals.sidebarWidth}px;
    }
`;

export const recolorDialogDesktopStyle = (showColorTools) => {
    return {
        zIndex: "50",
        transition: "bottom " + customVisuals.transitionSpeed + " ease",
        display: "block",
        position: "fixed",
        width: customVisuals.colorTool.width + "px",
        height: customVisuals.colorTool.height + "px",
        bottom: showColorTools
            ? customVisuals.overlayMargins
            : customVisuals.titleBarHeight -
              customVisuals.colorTool.height +
              "px",
        right: customVisuals.overlayMargins + "px",
        borderRadius: "5px",
        backgroundColor: `rgba(255,255,255,${customVisuals.opacity})`,
        boxShadow:
            "0 10px 20px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
    };
};

export const infoDialogDesktopStyle = (showPicInfo) => {
    return {
        position: "fixed",
        bottom: showPicInfo
            ? customVisuals.overlayMargins
            : 0 - customVisuals.infoTool.height + "px",
        left: customVisuals.overlayMargins + customVisuals.sidebarWidth + "px",
        zIndex: "50",
        transition: "bottom " + customVisuals.transitionSpeed + " ease",
    };
};

export const infoDialogMobileStyle = (showPicInfo) => {
    return {
        display: showPicInfo ? "block" : "none",
        position: "fixed",
        zIndex: "150",
        width: "100vw",
        height: "100vh",
        left: 0,
    };
};

export const noDialogStyle = {
    display: "none",
};

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    margin: 25px 0 25px 0;
`;

export const ModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
`;

export const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 640,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    outline: "none",
};

export const lightboxStyle = (screenWidth, screenHeight) => {
    return {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: screenWidth - 240,
        maxHeight: screenHeight - 240,
        bgcolor: "background.paper",
        borderRadius: "5px",
        boxShadow: 24,
        p: 4,
        outline: "none",
        overflow: "scroll",
    };
};
