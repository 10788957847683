import OpenSeaDragon from "openseadragon";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import {
    ZoomIn,
    ZoomOut,
    Palette,
    Contrast,
    Circle,
    Visibility,
    VisibilityOff,
    Info,
    ChatBubble,
    ChatBubbleOutline,
    QuestionMark,
    SquareOutlined,
    PentagonOutlined,
    KeyboardArrowRight,
    CenterFocusStrong,
    Construction,
    AddCircle,
    FilterList,
    PlayArrow,
    PlayCircle,
    ExpandMore,
    InfoOutlined,
    Delete,
    Close,
    Panorama,
    Launch,
    DeleteOutline,
} from "@mui/icons-material";
import {
    modalStyle,
    ModalHeader,
    ModalBody,
    ModalFooter,
    lightboxStyle,
} from "../styles.js";
import {
    blobToBase64,
    stringifyObjectVals,
    numberifyObjectVals,
    getTokenStrFromStorage,
    filterObjsInArrayByAttributeRange,
    remoteGetHists,
    formatDateAndTime,
    arrayIncludesObjWithAttribute,
} from "../helperFuncs";
import {
    paths,
    numbers,
    recolorParamConstants,
    thumbnailConstants,
    customVisuals,
    histOptions,
    defaultTheme,
} from "../Constants";
import SeadragonToolbar from "../seadragontoolbar";
import { noDialogStyle, recolorDialogDesktopStyle } from "../styles.js";
import Typography from "@mui/material/Typography";
import Filters from "./openseadragon-filtering";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Zoom from "@mui/material/Zoom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as Annotorious from "@recogito/annotorious-openseadragon";
import "./custom-annotorious.css";
import useWindowDimensions from "../windowDimensions.js";
import { isMobile, MOBILE_WIDTH } from "../windowDimensions.js";
import { CircularProgress } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { LZString } from "../compression/index.js";

require("dotenv").config();

const ButtonCol = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    width: 64px;
    position: fixed;
    z-index: 100;
    @media (min-width: ${MOBILE_WIDTH}px) {
        flex-direction: row-reverse;
        top: ${customVisuals.overlayMargins + customVisuals.navbarHeight}px;
        right: ${customVisuals.overlayMargins}px;
    }
    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: column;
        bottom: ${customVisuals.mobileMargins}px;
        right: ${customVisuals.mobileMargins}px;
        flex-direction: row-reverse;
        gap: 8px;
    }
`;

const AnnotationsRow = styled.div`
    position: fixed;
    top: ${customVisuals.overlayMargins + customVisuals.navbarHeight}px;
    left: ${customVisuals.overlayMargins + customVisuals.sidebarWidth}px;
    z-index: 100;

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 62px;
    height: 62px;
    border-radius: 5px;
    overflow: hidden;
    transition: width 0.36s ease;
    transition-delay: 3s;

    background-color: white;

    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.1);

    width: fit-content;
`;

const SeadragonToolWrapper = styled.div`
    padding: 0px 0 16px 0;
    display: flex;
    flex-grow: 1;
    height: 100%;
`;

const RecolorToolWrapper = styled.div`
    padding: 16px 16px 0 16px;
`;

const HistomicsToolWrapper = styled.div`
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
`;

const HistTools = styled.div`
    padding: 0 0 12px 0;
    margin: 0 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: fit-content;
`;

const HistToolRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: fit-content;
`;

const HistContent = styled.div`
    overflow: auto;
    height: 300px;
    overflow-y: auto;
    padding: 0 0 50px 0;
`;

const HistRow = styled.div``;

const HistRowHeader = styled.div`
    height: fit-content;
    background-color: ${defaultTheme.palette.secondary.main};
    color: white;
    border-radius: 5px 5px 0 0;
    display: flex;
    flex-direction: row;
    padding: 6px 2em 6px 2em;
`;

const HistInternal = styled.div`
    height: fit-content;
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const pushDefaultParams = async (imageId, params, token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token " + token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        image_id: imageId,
        object: params,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    fetch(
        process.env.REACT_APP_BACKEND_URL + "/api/v1/color_presets/",
        requestOptions
    )
        .then((response) => response.text())
        .then((result) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
};

const getRemoteAnnotations = async (imageID) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL +
                "/api/v1/annotation/?image_id=" +
                imageID,
            requestOptions
        )
            .then((response) => {
                let lol = response.json();
                // console.log(lol);
                return lol;
            })
            .then((result) => {
                resolve(JSON.parse(result.annotation));
            })
            .catch((error) => console.log("error", error));
    });
};

const setRemoteAnnotations = async (newAnnotations, imageID) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

    var formdata = new FormData();
    formdata.append("image_id", imageID);
    formdata.append("annotation", JSON.stringify(newAnnotations));

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
    };

    fetch(
        process.env.REACT_APP_BACKEND_URL + "/api/v1/annotation/",
        requestOptions
    )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
};

const FatSeaDragon = (props) => {
    const { height, width } = useWindowDimensions();

    const [refresh, setRefresh] = useState(0);

    const [viewer, setViewer] = useState(null);

    const [anno, setAnno] = useState(null);
    const [showAnnotations, setShowAnnotations] = useState(true);
    const [annoShapeMode, setAnnoShapeMode] = useState("rect"); // Either "rect" or "polygon"
    const [annoInfoModalOpen, setAnnoInfoModalOpen] = useState(false);

    const [showColorTools, setShowColorTools] = useState(false); // Recolor is always on; it's just not always shown (expanded)

    const [recolorParams, setRecolorParams] = useState(
        props.colorPresets !== null && props.colorPresets !== undefined
            ? props.colorPresets
            : stringifyObjectVals(recolorParamConstants.defaultParams)
    ); // To disable recolor, just reset to defaultParams

    const [resetKey, setResetKey] = useState(0);

    const [showAlert, setShowAlert] = useState(false);

    const [lastEdited, setLastEdited] = useState(0);

    const [creatingNewHist, setCreatingNewHist] = useState(false);

    const [hists, setHists] = useState([]);
    const [histModalOpen, setHistModalOpen] = useState(-1); // -1 means no modal open, 0 means create new, 1 means filter existing
    const [histSearchMin, setHistSearchMin] = useState(histOptions.min);
    const [histSearchMax, setHistSearchMax] = useState(histOptions.max);
    const [newHistMinSize, setNewHistMinSize] = useState(
        histOptions.newHistMinSize
    );

    const [lightboxSrc, setLightboxSrc] = useState("");
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [infoBoxOpen, setInfoBoxOpen] = useState(false);
    const [infoBoxSrc, setInfoBoxSrc] = useState(<></>);

    const InitOpenseadragon = () => {
        viewer && viewer.destroy();

        let osd = OpenSeaDragon({
            id: "openSeaDragon",
            prefixUrl: props.prefixUrl,
            tileSources: props.tileSources,
            animationTime: 0.5,
            blendTime: 0.1,
            constrainDuringPan: true,
            maxZoomPixelRatio: 2,
            minZoomLevel: null,
            maxZoomLevel: null,
            visibilityRatio: 1,
            zoomPerScroll: 2,
            showNavigator: props.showNavigator,
            zoomInButton: "zoom-in",
            zoomOutButton: "zoom-out",
            homeButton: "home",
            fullPageButton: "full-page",
            nextButton: "next",
            previousButton: "previous",
            crossOriginPolicy: "Anonymous",
        });

        // osd.setFilterOptions({
        //     filters: {
        //         processors: [Filters.BRIGHTNESS(-50), Filters.INVERT()],
        //     },
        // });

        setViewer(osd);

        if (showAnnotations) {
            const config = {};
            const annotate = Annotorious(osd, config);
            annotate.setDrawingTool(annoShapeMode);
            setAnno(annotate);
        }
    };

    const InitAnnotations = async () => {
        // First, get the annotations from the server
        // If there are such annotations, put them in the viewer

        getRemoteAnnotations(props.imageId).then((annotationsFromRemote) => {
            if (annotationsFromRemote) {
                anno.setAnnotations(annotationsFromRemote);
            }
        });

        // Overwrite createAnnotation function
        // On the creation of a new annotation, create an array of annotations by adding a new annotation to the array from remote
        anno.on("createAnnotation", (annotation) => {
            getRemoteAnnotations(props.imageId).then(
                (annotationsFromRemote) => {
                    let newAnnotations = [];
                    if (annotationsFromRemote) {
                        newAnnotations = [...annotationsFromRemote, annotation];
                    } else {
                        newAnnotations = [annotation];
                    }
                    setRemoteAnnotations(newAnnotations, props.imageId);
                }
            );
        });

        // Overwrite updateAnnotation function
        // On the update of an annotation, update the array of annotations from remote
        anno.on("updateAnnotation", (annotation, previous) => {
            getRemoteAnnotations(props.imageId).then(
                (annotationsFromRemote) => {
                    const newAnnotations = annotationsFromRemote.map((val) => {
                        if (val.id === annotation.id) return annotation;
                        return val;
                    });
                    setRemoteAnnotations(newAnnotations, props.imageId);
                }
            );
        });

        // Overwrite deleteAnnotation function
        // On the deletion of an annotation, delete the annotation from the array of annotations from remote
        anno.on("deleteAnnotation", (annotation) => {
            getRemoteAnnotations(props.imageId).then(
                (annotationsFromRemote) => {
                    const newAnnotations = annotationsFromRemote.filter(
                        (val) => val.id !== annotation.id
                    );
                    setRemoteAnnotations(newAnnotations, props.imageId);
                }
            );
        });
    };

    const handleBlobUpload = async (blobStr) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var formdata = new FormData();
        formdata.append("image_id", props.imageId);
        formdata.append("b64object", blobStr);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL + "/api/v1/update_thumbnail/",
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));
    };

    const setHistSearch = (min, max) => {
        setHistSearchMin(min);
        setHistSearchMax(max);
    };

    const openHistModal = (num) => {
        if (num === histModalOpen) {
            setHistModalOpen(-1);
        } else {
            setHistModalOpen(num);
        }
    };

    const closeHistModal = () => {
        setHistModalOpen(-1);
    };

    const createNewHist = async (newHistMinSize) => {
        return new Promise((resolve, reject) => {
            const canvasElement = document.getElementsByTagName("canvas")[0];

            const CompressionRatio = 0.5;

            // Compress the image
            const newCanvas = document.createElement("canvas");
            newCanvas.width = canvasElement.width * CompressionRatio;
            newCanvas.height = canvasElement.height * CompressionRatio;
            const ctx = newCanvas.getContext("2d");
            ctx.drawImage(
                canvasElement,
                0,
                0,
                newCanvas.width,
                newCanvas.height
            );

            // Get the new image data as a blob
            newCanvas.toBlob((blob) => {
                blobToBase64(blob).then((base64str) => {
                    var myHeaders = new Headers();
                    myHeaders.append(
                        "Authorization",
                        "Token " + getTokenStrFromStorage()
                    );

                    var formdata = new FormData();
                    formdata.append("image_id", props.imageId + "");
                    formdata.append("b64object", base64str + "");
                    formdata.append("min_size", newHistMinSize + "");

                    var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: formdata,
                        redirect: "follow",
                    };

                    fetch(
                        process.env.REACT_APP_BACKEND_URL +
                            "/api/v1/segmentation/",
                        requestOptions
                    )
                        .then((response) => response.text())
                        .then((result) => {
                            console.log(result);
                            resolve(result);
                        })
                        .catch((error) => {
                            console.log("error", error);
                            reject(error);
                        });
                });
            });
        });
    };

    const deleteHist = (histId) => {
        return new Promise((resolve, reject) => {
            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Token " + getTokenStrFromStorage()
            );

            var requestOptions = {
                method: "DELETE",
                headers: myHeaders,
                redirect: "follow",
            };

            fetch(
                process.env.REACT_APP_BACKEND_URL +
                    "/api/v1/delete_histomics/?histomics=" +
                    histId,
                requestOptions
            )
                .then((response) => response.text())
                .then((result) => {
                    console.log(result);
                    resolve(result);
                })
                .catch((error) => {
                    console.log("error", error);
                    reject(error);
                });
        });
    };

    useEffect(() => {
        remoteGetHists(props.imageId)
            .then((hists) => {
                console.log(hists);

                if (
                    arrayIncludesObjWithAttribute(hists, "status", "processing")
                ) {
                    setTimeout(() => {
                        setRefresh(refresh + 1);
                    }, numbers.PING_DELAY);
                }

                let newHists = filterObjsInArrayByAttributeRange(
                    hists,
                    "operation",
                    histSearchMin,
                    histSearchMax,
                    "status",
                    "processing"
                );
                console.log(newHists);
                setHists(newHists);
            })
            .catch((error) => console.log("error", error));
    }, [refresh, histSearchMin, histSearchMax]);

    useEffect(() => {
        if (props.colorPresets !== null && props.colorPresets !== undefined) {
            setRecolorParams(stringifyObjectVals(props.colorPresets));
        } else {
            setRecolorParams(
                stringifyObjectVals(recolorParamConstants.defaultParams)
            );
        }
    }, [props.colorPresets]);

    useEffect(() => {
        InitOpenseadragon();

        return () => {
            viewer && viewer.destroy();
        };
    }, [showAnnotations, annoShapeMode]);

    useEffect(() => {
        if (anno) {
            InitAnnotations();
        }
    }, [anno]);

    useEffect(() => {
        // add filters
        viewer &&
            viewer.setFilterOptions({
                filters: {
                    processors: [
                        Filters.CUSTOM_RECOLORING(
                            numberifyObjectVals(recolorParams)
                        ),
                        Filters.GAMMA(Number(recolorParams.CONTRAST)),
                    ],
                },
            });
    }, [recolorParams]);

    useEffect(() => {
        if (showAlert) {
            setTimeout(() => {
                setShowAlert(false);
            }, numbers.ALERT_TIMEOUT);
        }
    }, [showAlert]);

    const [tabVal, setTabVal] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabVal(newValue);
    };
    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            "aria-controls": `vertical-tabpanel-${index}`,
        };
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role='tabpanel'
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const createNewHistHandler = () => {
        setCreatingNewHist(true);
        createNewHist(newHistMinSize)
            .then((result) => {
                setCreatingNewHist(false);
                setRefresh(refresh + 1);
            })
            .catch((error) => {
                setCreatingNewHist(false);
                console.log("error", error);
            });
    };

    return (
        <div>
            <Modal
                open={infoBoxOpen}
                onClose={() => {
                    setInfoBoxOpen(false);
                }}
            >
                <Box sx={modalStyle}>
                    <ModalHeader>
                        <Typography
                            id='modal-modal-title'
                            variant='h6'
                            component='h2'
                        >
                            About this Histomic
                        </Typography>
                        <IconButton
                            color='secondary'
                            aria-label='Close Modal'
                            onClick={() => {
                                setInfoBoxOpen(false);
                            }}
                        >
                            <Close />
                        </IconButton>
                    </ModalHeader>
                    <ModalBody>{infoBoxSrc}</ModalBody>
                </Box>
            </Modal>
            <Modal
                open={lightboxOpen}
                onClose={() => {
                    setLightboxOpen(false);
                }}
            >
                <Box sx={lightboxStyle(width, height)}>
                    <ModalHeader>
                        <Typography
                            id='modal-modal-title'
                            variant='h6'
                            component='h2'
                        >
                            Histomic Viewer
                        </Typography>
                        <IconButton
                            color='secondary'
                            aria-label='Close Modal'
                            onClick={() => {
                                setLightboxOpen(false);
                            }}
                        >
                            <Close />
                        </IconButton>
                    </ModalHeader>
                    <ModalBody>
                        <img src={lightboxSrc} alt='lightbox' />
                    </ModalBody>
                </Box>
            </Modal>
            <Modal
                open={annoInfoModalOpen}
                onClose={() => setAnnoInfoModalOpen(false)}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        borderRadius: 5,
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography
                        id='modal-modal-title'
                        variant='h6'
                        component='h2'
                    >
                        Welcome to Annotations Mode!
                    </Typography>
                    <ToggleButtonGroup
                        value={annoShapeMode}
                        exclusive
                        onChange={(event, newValue) => {
                            event.preventDefault();
                            setAnnoShapeMode(newValue);
                        }}
                        sx={{
                            margin: "20px 0 12px 0",
                        }}
                    >
                        <ToggleButton
                            value='rect'
                            disabled={annoShapeMode === "rect"}
                        >
                            <Tooltip title='Annotate with Rectangles' arrow>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        gap: "10px",
                                        height: "24px",
                                        width: "fit-content",
                                        margin: "0 0 0 0",
                                        padding: "0 0 0 0",
                                    }}
                                >
                                    <SquareOutlined />
                                    <p>Rectangular</p>
                                </div>
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton
                            value='polygon'
                            disabled={annoShapeMode === "polygon"}
                        >
                            <Tooltip
                                title='Annotate with Custom Polygons'
                                arrow
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        gap: "10px",
                                        height: "24px",
                                        width: "fit-content",
                                        margin: "0 0 0 0",
                                        padding: "0 0 0 0",
                                    }}
                                >
                                    <PentagonOutlined />
                                    <p>Polygonal</p>
                                </div>
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                        {annoShapeMode === "rect" ? (
                            <>
                                To add a new annotation, hold <b>Shift</b>, then
                                click anywhere and drag.
                                <br />
                                <br />
                                Release the mouse to finish.
                            </>
                        ) : (
                            <>
                                To add a new annotation, hold <b>Shift</b>, then
                                click anywhere to add a point.
                                <br />
                                <br />
                                Double click closes the polygon and finishes the
                                annotation.
                            </>
                        )}
                    </Typography>
                </Box>
            </Modal>
            <Snackbar open={showAlert}>
                <Alert
                    onClose={() => setShowAlert(false)}
                    severity='success'
                    sx={{ width: "100%" }}
                >
                    Saved as Default!
                </Alert>
            </Snackbar>

            <div>
                {isMobile(width) ? (
                    <></>
                ) : (
                    <AnnotationsRow>
                        <Tooltip
                            title={
                                showAnnotations
                                    ? "Turn Off Annotations"
                                    : "Turn On Annotations"
                            }
                            arrow
                        >
                            <Button
                                variant='text'
                                sx={{
                                    height: "62px",
                                    backgroundColor: showAnnotations
                                        ? "rgba(12,60,96,0.12)"
                                        : "white",
                                    // on hover, change background color
                                    "&:hover": {
                                        backgroundColor: "#f8f8ff",
                                    },
                                    borderRadius: showAnnotations
                                        ? "5px 0px 0px 5px"
                                        : "62px",
                                }}
                                onClick={() => {
                                    setShowAnnotations(!showAnnotations);
                                }}
                            >
                                {showAnnotations ? (
                                    <ChatBubble />
                                ) : (
                                    <ChatBubbleOutline />
                                )}
                            </Button>
                        </Tooltip>
                        {showAnnotations ? (
                            <>
                                <Tooltip title='How do I annotate?' arrow>
                                    <Button
                                        variant='text'
                                        sx={{
                                            height: "62px",
                                            backgroundColor: "white",
                                            // on hover, change background color
                                            "&:hover": {
                                                backgroundColor: "#f8f8ff",
                                            },
                                            borderRadius: "62px",
                                        }}
                                        onClick={(e) => {
                                            setAnnoInfoModalOpen(true);
                                        }}
                                    >
                                        <QuestionMark />
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        annoShapeMode === "rect"
                                            ? "Switch to annotating with custom polygons"
                                            : "Switch to annotating with rectangles"
                                    }
                                    arrow
                                >
                                    <Button
                                        variant='text'
                                        sx={{
                                            height: "62px",
                                            backgroundColor: "white",
                                            // on hover, change background color
                                            "&:hover": {
                                                backgroundColor: "#f8f8ff",
                                            },
                                            borderRadius: "62px",
                                        }}
                                        onClick={() => {
                                            setAnnoShapeMode(
                                                annoShapeMode === "rect"
                                                    ? "polygon"
                                                    : "rect"
                                            );
                                        }}
                                    >
                                        {annoShapeMode === "rect" ? (
                                            <PentagonOutlined />
                                        ) : (
                                            <SquareOutlined />
                                        )}
                                    </Button>
                                </Tooltip>
                            </>
                        ) : (
                            <></>
                        )}
                    </AnnotationsRow>
                )}

                <ButtonCol>
                    {isMobile(width) ? (
                        <>
                            <Tooltip title='Show/Hide Image Info' arrow>
                                <Button
                                    variant='contained'
                                    sx={{
                                        height: "62px",
                                        margin: "2px 0 2px 0",
                                    }}
                                    onClick={() => {
                                        props.setShowPicInfo(
                                            !props.showPicInfo
                                        );
                                    }}
                                >
                                    <Info />
                                </Button>
                            </Tooltip>
                            <Tooltip title='Show/Hide Comments' arrow>
                                <Button
                                    variant='contained'
                                    sx={{
                                        height: "62px",
                                        margin: "2px 0 2px 0",
                                    }}
                                    onClick={() => {
                                        setShowAnnotations(!showAnnotations);
                                    }}
                                >
                                    {showAnnotations ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </Button>
                            </Tooltip>
                        </>
                    ) : (
                        <>
                            <div id='zoom-in'>
                                <Tooltip
                                    title='Zoom In'
                                    arrow
                                    placement='bottom'
                                >
                                    <Button
                                        variant='contained'
                                        sx={{
                                            height: "62px",
                                            margin: "2px 0 2px 0",
                                        }}
                                    >
                                        <ZoomIn />
                                    </Button>
                                </Tooltip>
                            </div>
                            <div id='zoom-out'>
                                <Tooltip
                                    title='Zoom Out'
                                    arrow
                                    placement='bottom'
                                >
                                    <Button
                                        variant='contained'
                                        sx={{
                                            height: "62px",
                                            margin: "2px 0 2px 0",
                                        }}
                                    >
                                        <ZoomOut />
                                    </Button>
                                </Tooltip>
                            </div>
                            {/* <div id="full-page">
                                <Tooltip title="Full Screen" arrow>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            height: "62px",
                                            margin: "2px 0 2px 0",
                                        }}
                                    >
                                        <Fullscreen />
                                    </Button>
                                </Tooltip>
                            </div> */}
                        </>
                    )}
                </ButtonCol>

                <div
                    id='openSeaDragon'
                    style={{
                        height: isMobile(width)
                            ? height + "px"
                            : height -
                              customVisuals.navbarHeight -
                              customVisuals.osdMargins * 2 +
                              "px",
                        width: isMobile(width)
                            ? width + "px"
                            : width -
                              customVisuals.osdMargins * 2 -
                              customVisuals.sidebarWidth +
                              "px",
                        margin: `${
                            isMobile(width) ? "0" : customVisuals.navbarHeight
                        }px 0 0 0`,
                        padding: "0 0 0 0",
                    }}
                />
            </div>
            <div
                style={
                    isMobile(width)
                        ? noDialogStyle
                        : recolorDialogDesktopStyle(showColorTools)
                }
            >
                <SeadragonToolbar
                    title={"Advanced Tools"}
                    minimized={!showColorTools}
                    callback={() => setShowColorTools(!showColorTools)}
                    icon={<Construction />}
                    mobile={isMobile(width)}
                />
                <SeadragonToolWrapper>
                    <Tabs
                        orientation='vertical'
                        variant='scrollable'
                        value={tabVal}
                        onChange={handleTabChange}
                        aria-label='Vertical tabs'
                        sx={{
                            borderRight: 1,
                            borderColor: "divider",
                            width: "64px",
                            padding: "0",
                        }}
                    >
                        <Tab
                            icon={
                                <Tooltip
                                    title='Recoloring'
                                    arrow
                                    placement='left'
                                >
                                    <Palette />
                                </Tooltip>
                            }
                            {...a11yProps(0)}
                            sx={{
                                margin: "24px 0 0 -12px",
                            }}
                        />
                        <Tab
                            icon={
                                <Tooltip
                                    title='histomics'
                                    arrow
                                    placement='left'
                                >
                                    <CenterFocusStrong />
                                </Tooltip>
                            }
                            {...a11yProps(1)}
                            sx={{
                                margin: "12px 0 0 -12px",
                            }}
                        />
                    </Tabs>
                    <TabPanel value={tabVal} index={0}>
                        <RecolorToolWrapper>
                            <Typography>
                                Adjust Red and Blue Color Composition
                            </Typography>
                            <Stack
                                spacing={2}
                                direction='row'
                                sx={{ mb: 1, margin: "10px 0 0 0" }}
                                alignItems='center'
                            >
                                <Circle sx={{ color: "red" }} />
                                <Slider
                                    key={resetKey * 1.0}
                                    aria-label='hue-slider'
                                    step={recolorParamConstants.steps.RED}
                                    min={recolorParamConstants.minParams.RED}
                                    max={recolorParamConstants.maxParams.RED}
                                    valueLabelDisplay='auto'
                                    onChangeCommitted={(event, value) => {
                                        setRecolorParams({
                                            ...recolorParams,
                                            RED: value.toString(),
                                        });
                                    }}
                                    defaultValue={recolorParams.RED}
                                />
                            </Stack>
                            <Stack
                                spacing={2}
                                direction='row'
                                sx={{ mb: 1, margin: "10px 0 0 0" }}
                                alignItems='center'
                            >
                                <Circle sx={{ color: "blue" }} />
                                <Slider
                                    key={resetKey * 2.0}
                                    aria-label='hue-slider'
                                    step={recolorParamConstants.steps.BLUE}
                                    min={recolorParamConstants.minParams.BLUE}
                                    max={recolorParamConstants.maxParams.BLUE}
                                    valueLabelDisplay='auto'
                                    onChangeCommitted={(event, value) => {
                                        setRecolorParams({
                                            ...recolorParams,
                                            BLUE: value.toString(),
                                        });
                                    }}
                                    defaultValue={recolorParams.BLUE}
                                />
                            </Stack>
                            <Divider sx={{ margin: "20px 0 20px 0" }} />
                            <Typography>Adjust Image Contrast</Typography>
                            <Stack
                                spacing={2}
                                direction='row'
                                sx={{ mb: 1, margin: "10px 0 0 0" }}
                                alignItems='center'
                            >
                                <Contrast />
                                <Slider
                                    key={resetKey * 3.0}
                                    aria-label='contrast-slider'
                                    step={recolorParamConstants.steps.CONTRAST}
                                    min={
                                        recolorParamConstants.minParams.CONTRAST
                                    }
                                    max={
                                        recolorParamConstants.maxParams.CONTRAST
                                    }
                                    valueLabelDisplay='auto'
                                    onChangeCommitted={(event, value) => {
                                        setRecolorParams({
                                            ...recolorParams,
                                            CONTRAST: value.toString(),
                                        });
                                    }}
                                    defaultValue={recolorParams.CONTRAST}
                                    marks={[
                                        {
                                            value: recolorParamConstants
                                                .defaultParams.CONTRAST,
                                            label: "", //△
                                        },
                                    ]}
                                />
                            </Stack>
                            <Divider sx={{ margin: "20px 0 20px 0" }} />
                            <Stack
                                spacing={2}
                                direction='row'
                                sx={{ mb: 1, margin: "10px 0 0 0" }}
                                alignItems='center'
                            >
                                <Button
                                    variant='outlined'
                                    onClick={() => {
                                        if (
                                            pushDefaultParams(
                                                props.imageId,
                                                numberifyObjectVals(
                                                    recolorParams
                                                ),
                                                props.token
                                            )
                                        ) {
                                            const canvasElement =
                                                document.getElementsByTagName(
                                                    "canvas"
                                                )[0];
                                            canvasElement.toBlob(
                                                (blob) => {
                                                    blobToBase64(blob).then(
                                                        (base64) => {
                                                            // blob format: data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAIBA...
                                                            handleBlobUpload(
                                                                base64
                                                            );
                                                        }
                                                    );
                                                },
                                                thumbnailConstants.format,
                                                thumbnailConstants.compressionFactor
                                            );
                                            setShowAlert(true);
                                        }
                                    }}
                                >
                                    Save as Default
                                </Button>
                                <Button
                                    variant='outlined'
                                    color='error'
                                    onClick={() => {
                                        setRecolorParams(
                                            stringifyObjectVals(
                                                recolorParamConstants.defaultParams
                                            )
                                        );
                                        setResetKey(resetKey + 1);
                                    }}
                                >
                                    Reset
                                </Button>
                            </Stack>
                        </RecolorToolWrapper>
                    </TabPanel>
                    <TabPanel value={tabVal} index={1}>
                        <HistomicsToolWrapper>
                            <HistTools>
                                <HistToolRow>
                                    <Button
                                        variant={
                                            histModalOpen === 0
                                                ? "contained"
                                                : "outlined"
                                        }
                                        onClick={() => openHistModal(0)}
                                        startIcon={<AddCircle />}
                                    >
                                        New
                                    </Button>
                                    <Button
                                        variant={
                                            histModalOpen === 1
                                                ? "contained"
                                                : "outlined"
                                        }
                                        onClick={() => openHistModal(1)}
                                        startIcon={<FilterList />}
                                    >
                                        Filter (by Box Size)
                                    </Button>
                                </HistToolRow>

                                {/* <Zoom
                                    in={
                                        histModalOpen === 0 ||
                                        histModalOpen === 1
                                    }
                                > */}
                                <>
                                    <HistToolRow>
                                        {histModalOpen === 0 ? (
                                            <>
                                                <TextField
                                                    autoFocus
                                                    sx={{
                                                        width: "150px",
                                                    }}
                                                    size='small'
                                                    inputProps={{
                                                        inputMode: "numeric",
                                                        pattern: "[0-9]*",
                                                    }}
                                                    id='outlined-basic'
                                                    label='Box Size'
                                                    variant='outlined'
                                                    value={newHistMinSize}
                                                    onChange={(event) => {
                                                        // clean input to only allow numbers
                                                        const input =
                                                            event.target.value;

                                                        const cleanInput =
                                                            input.replace(
                                                                /[^0-9]/g,
                                                                ""
                                                            );

                                                        setNewHistMinSize(
                                                            cleanInput
                                                        );
                                                    }}
                                                    // on enter key
                                                    onKeyPress={(event) => {
                                                        if (
                                                            event.key ===
                                                            "Enter"
                                                        ) {
                                                            createNewHistHandler();
                                                        }
                                                    }}
                                                />
                                                <IconButton
                                                    color='primary'
                                                    variant='contained'
                                                    onClick={
                                                        createNewHistHandler
                                                    }
                                                >
                                                    {creatingNewHist ? (
                                                        <CircularProgress />
                                                    ) : (
                                                        <PlayCircle />
                                                    )}
                                                </IconButton>
                                            </>
                                        ) : histModalOpen === 1 ? (
                                            <>
                                                <TextField
                                                    autoFocus={lastEdited === 0}
                                                    size='small'
                                                    sx={{
                                                        width: "100px",
                                                    }}
                                                    inputProps={{
                                                        inputMode: "numeric",
                                                        pattern: "[0-9]*",
                                                    }}
                                                    id='outlined-basic'
                                                    label='Min. Box Size'
                                                    variant='outlined'
                                                    value={histSearchMin}
                                                    onChange={(event) => {
                                                        // clean input to only allow numbers
                                                        const input =
                                                            event.target.value;

                                                        const cleanInput =
                                                            input.replace(
                                                                /[^0-9]/g,
                                                                ""
                                                            );

                                                        setHistSearchMin(
                                                            cleanInput
                                                        );
                                                        setLastEdited(0);
                                                    }}
                                                    onBlur={(event) => {
                                                        setLastEdited(-1);
                                                    }}
                                                />
                                                <TextField
                                                    autoFocus={lastEdited === 1}
                                                    size='small'
                                                    sx={{
                                                        width: "100px",
                                                    }}
                                                    inputProps={{
                                                        inputMode: "numeric",
                                                        pattern: "[0-9]*",
                                                    }}
                                                    id='outlined-basic'
                                                    label='Max. Box Size'
                                                    variant='outlined'
                                                    value={histSearchMax}
                                                    onChange={(event) => {
                                                        // clean input to only allow numbers
                                                        const input =
                                                            event.target.value;

                                                        const cleanInput =
                                                            input.replace(
                                                                /[^0-9]/g,
                                                                ""
                                                            );

                                                        setHistSearchMax(
                                                            cleanInput
                                                        );
                                                        setLastEdited(1);
                                                    }}
                                                    onBlur={(event) => {
                                                        setLastEdited(-1);
                                                    }}
                                                />
                                            </>
                                        ) : null}
                                    </HistToolRow>
                                    <HistContent>
                                        <HistRowHeader>
                                            <Typography
                                                sx={{
                                                    width: "33%",
                                                    flexShrink: 0,
                                                    marginLeft: "-1em",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                ID
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Box Size
                                            </Typography>
                                        </HistRowHeader>
                                        {hists.length !== 0 &&
                                        refresh !== "1" ? (
                                            hists.map((hist) => {
                                                if (
                                                    hist.status === "processing"
                                                ) {
                                                    return (
                                                        <HistRow>
                                                            <Accordion disabled>
                                                                <AccordionSummary
                                                                    expandIcon={
                                                                        <div />
                                                                    }
                                                                    aria-controls='panel1a-content'
                                                                    id='panel1a-header'
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            width: "33%",
                                                                            flexShrink: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            hist.id
                                                                        }
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{
                                                                            color: "text.secondary",
                                                                        }}
                                                                    >
                                                                        Processing...
                                                                    </Typography>
                                                                </AccordionSummary>
                                                            </Accordion>
                                                        </HistRow>
                                                    );
                                                } else {
                                                    return (
                                                        <HistRow>
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={
                                                                        <ExpandMore />
                                                                    }
                                                                    aria-controls='panel1a-content'
                                                                    id='panel1a-header'
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            width: "33%",
                                                                            flexShrink: 0,
                                                                        }}
                                                                    >
                                                                        {
                                                                            hist.id
                                                                        }
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{
                                                                            color: "text.secondary",
                                                                        }}
                                                                    >
                                                                        {
                                                                            hist.operation
                                                                        }
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <HistInternal>
                                                                        <Tooltip
                                                                            title='View Boxed Image'
                                                                            arrow
                                                                        >
                                                                            <Button
                                                                                variant='text'
                                                                                startIcon={
                                                                                    <Launch />
                                                                                }
                                                                                onClick={() => {
                                                                                    setLightboxSrc(
                                                                                        hist.base64_boxed
                                                                                    );
                                                                                    setLightboxOpen(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Boxed
                                                                            </Button>
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title='View Masked Image'
                                                                            arrow
                                                                        >
                                                                            <Button
                                                                                variant='text'
                                                                                startIcon={
                                                                                    <Launch />
                                                                                }
                                                                                onClick={() => {
                                                                                    setLightboxSrc(
                                                                                        hist.base64_masked
                                                                                    );
                                                                                    setLightboxOpen(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Masked
                                                                            </Button>
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title='Delete this Histomic'
                                                                            arrow
                                                                        >
                                                                            <IconButton
                                                                                color='primary'
                                                                                onClick={() => {
                                                                                    // TODO: delete hist
                                                                                    deleteHist(
                                                                                        hist.id
                                                                                    ).then(
                                                                                        () => {
                                                                                            setTimeout(
                                                                                                () => {
                                                                                                    setRefresh(
                                                                                                        refresh +
                                                                                                            1
                                                                                                    );
                                                                                                },
                                                                                                numbers.PING_DELAY
                                                                                            );
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <DeleteOutline />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title='Show Metadata'
                                                                            arrow
                                                                        >
                                                                            <IconButton
                                                                                color='primary'
                                                                                onClick={() => {
                                                                                    setInfoBoxSrc(
                                                                                        <>
                                                                                            <b>
                                                                                                Created:{" "}
                                                                                            </b>{" "}
                                                                                            {formatDateAndTime(
                                                                                                hist.created_at
                                                                                            )}
                                                                                        </>
                                                                                    );
                                                                                    setInfoBoxOpen(
                                                                                        true
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <InfoOutlined />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </HistInternal>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </HistRow>
                                                    );
                                                }
                                            })
                                        ) : (
                                            <Box
                                                sx={{
                                                    padding: "16px",
                                                    fontStyle: "italic",
                                                }}
                                            >
                                                None to display
                                            </Box>
                                        )}
                                    </HistContent>
                                </>
                                {/* </Zoom> */}
                            </HistTools>
                        </HistomicsToolWrapper>
                    </TabPanel>
                </SeadragonToolWrapper>
            </div>
        </div>
    );
};

export default FatSeaDragon;
