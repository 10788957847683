import { Add, Close, DeleteOutline } from "@mui/icons-material";
import * as React from "react";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Modal,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { addSuperuser, deleteSuperuser } from "./helperFuncs.js";
import { ModalBody, ModalFooter, ModalHeader, modalStyle } from "./styles.js";

/**
 * Renders a simple unordered list with deletable items, and a button to add new items.
 * @param {*} props should contain the following props:
 * @param {object[]} props.arr - the items in the list, each element a user
 * @param {function} props.refresh - a callback to request a refresh of the props from origin
 * @returns the JSX to render
 */
const UserList = (props) => {
    const [items, setItems] = React.useState(props.arr);

    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [addEmail, setAddEmail] = React.useState("");

    React.useEffect(() => {
        setItems(props.arr);
    }, [props.arr]);

    const handleAdd = () => {
        setAddModalOpen(true);
    };

    const customList = (items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Tooltip
                        title={"Add a new superuser"}
                        arrow
                        placement="right"
                    >
                        <IconButton
                            sx={{ my: 0.5 }}
                            variant="contained"
                            size="large"
                            onClick={handleAdd}
                            disabled={false}
                            aria-label="add new"
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>
                }
                title={"Current Superusers"}
                subheader={`${items.length} superusers`}
            />
            <Divider />
            <List
                sx={{
                    width: 600,
                    height: 230,
                    bgcolor: "background.paper",
                    overflow: "auto",
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((item) => {
                    const labelId = `transfer-list-all-item-${item.id}-label`;

                    return (
                        <ListItem key={item.id} role="listitem" button>
                            <ListItemIcon>
                                <Tooltip
                                    title={"Remove this user as superuser"}
                                    arrow
                                    placement="right"
                                >
                                    <IconButton
                                        onClick={() => {
                                            deleteSuperuser(item.email).then(
                                                () => {
                                                    props.refresh();
                                                }
                                            );
                                        }}
                                    >
                                        <DeleteOutline />
                                    </IconButton>
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={`${item.first_name} ${item.last_name}`}
                            />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    const closeModal = () => {
        setAddModalOpen(false);
        setAddEmail("");
    };

    const handleAddSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        addSuperuser(addEmail).then(() => {
            props.refresh();
        });
        closeModal();
    };

    return (
        <>
            <Modal
                open={addModalOpen}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <ModalHeader>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Add a New Superuser
                        </Typography>
                        <IconButton
                            color="secondary"
                            aria-label="Close Modal"
                            onClick={closeModal}
                        >
                            <Close />
                        </IconButton>
                    </ModalHeader>
                    <ModalBody>
                        <TextField
                            id="standard-basic"
                            label="Email"
                            variant="standard"
                            value={addEmail}
                            onChange={(e) => setAddEmail(e.target.value)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" onClick={handleAddSubmit}>
                            Submit
                        </Button>
                    </ModalFooter>
                </Box>
            </Modal>
            <Grid
                container
                spacing={2}
                sx={{
                    marginTop: "48px",
                }}
                justifyContent="left"
                alignItems="left"
            >
                <Grid item xs={12} md={12}>
                    {customList(items)}
                </Grid>
            </Grid>
        </>
    );
};

export default UserList;
