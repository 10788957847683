import { useEffect, useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import {
    Avatar,
    Box,
    Button,
    Container,
    CssBaseline,
    Grid,
    Link,
    TextField,
    Typography,
    Snackbar,
    Alert,
} from "@mui/material";
import Copyright from "./copyright";
import { asyncAuth, emailValidation } from "./helperFuncs.js";

require("dotenv").config();

import { paths, numbers } from "./Constants.js";

const Signup = (props) => {
    let navigate = useNavigate();

    const [showEmailInvalid, setShowEmailInvalid] = useState(false);

    useEffect(() => {
        asyncAuth()
            .then((authRes) => {
                console.log(
                    "already authenticated! redirecting to dashboard..."
                );
                navigate(paths.DASHBOARD);
            })
            .catch((err) => {
                console.log("not authenticated! staying on signup...");
            });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const toSend = {
            email: data.get("email"),
            password: data.get("password"), //TODO: encrypt password
            firstName: data.get("firstName"),
            lastName: data.get("lastName"),
        };

        // check if email is valid

        if (!emailValidation(toSend.email)) {
            setShowEmailInvalid(true);
            setTimeout(function () {
                setShowEmailInvalid(false);
            }, numbers.ALERT_TIMEOUT); // remove the alert after 6 seconds
            return;
        }

        // if so, send the request to the server

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            username: toSend.email, // use email as username
            email: toSend.email,
            password: toSend.password,
            first_name: toSend.firstName,
            last_name: toSend.lastName,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL + "/auth/create_user/",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                navigate({
                    pathname: paths.LOGIN,
                    search: "success",
                });
            })
            .catch((error) => console.log("error", error));
    };

    return (
        <>
            <Snackbar
                open={showEmailInvalid}
                autoHideDuration={numbers.ALERT_TIMEOUT}
            >
                <Alert severity="error" sx={{ width: "100%" }}>
                    Invalid Email
                </Alert>
            </Snackbar>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit}
                        sx={{ mt: 3 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox value="allowExtraEmails" color="primary" />}
                        label="I want to receive inspiration, marketing promotions and updates via email."
                    />
                    </Grid> */}
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link
                                    onClick={() => navigate(paths.LOGIN)}
                                    variant="body2"
                                >
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </>
    );
};

export default Signup;
