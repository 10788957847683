// This file contains constants used across the frontend.

const dynamicPathAffixes = {
    imgID: "imgid",
    orgID: "orgid",
};

const paths = {
    DEFAULT: "/",
    CATCHALL: "*",
    LOGIN: "/login",
    SIGNUP: "/register",
    DASHBOARD: "/:" + dynamicPathAffixes.orgID + "/dashboard",
    BROWSER: "/:" + dynamicPathAffixes.orgID + "/browse",
    UPLOAD: "/:" + dynamicPathAffixes.orgID + "/upload",
    ACCOUNT: "/account",
    ADMIN: "/:" + dynamicPathAffixes.orgID + "/admin",
    GODVIEW: "/superusers",
    REDIRECTHUB: "/redirecting",
    VIEWER:
        "/:" +
        dynamicPathAffixes.orgID +
        "/viewer/:" +
        dynamicPathAffixes.imgID,
};

const pagesWhereItsOKIfOrgContextInvalid = [paths.GODVIEW];

const navSubsetOfPaths = ["DASHBOARD", "BROWSER"];
const adminNavSubsetOfPaths = ["DASHBOARD", "BROWSER", "UPLOAD", "ADMIN"];

// chosen query strings below must not conflict with chosen path names above: no overlap allowed
const queries = {
    REFRESH_TRIGGER: "loading",
    SIGNUP_SUCCESS: "success",
    UPLOAD_SUCCESS: "uploaded",
};

const numbers = {
    ALERT_TIMEOUT: 6000,
    PING_DELAY: 1500,
    REFRESH_DELAY: 1000,
    DYNAMIZE_DELAY: 1,
};

const urls = {
    THUMBNAIL_FALLBACK:
        "https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder.png",
    LOGIN_BG:
        "https://unsplash.com/photos/mbL91Lg56zc/download?ixid=MnwxMjA3fDB8MXxzZWFyY2h8ODJ8fGNlbGxzfGVufDB8fHx8MTY1OTM5MTA1Nw&force=true&w=2400",
};

const recolorParamConstants = {
    defaultParams: {
        RED: 0,
        BLUE: 0,
        CONTRAST: 1,
    },
    maxParams: {
        RED: 20,
        BLUE: 20,
        CONTRAST: 3,
    },
    minParams: {
        RED: 0,
        BLUE: 0,
        CONTRAST: 0,
    },
    steps: {
        RED: 0.1,
        BLUE: 0.1,
        CONTRAST: 0.05,
    },
};

const thumbnailConstants = {
    compressionFactor: 0.95,
    format: "image/jpeg",
};

const maxCharLengthInValueCol = 24;

const customVisuals = {
    transitionSpeed: "0.36s",
    overlayMargins: 42,
    osdMargins: 24,
    mobileMargins: 12,

    titleBarHeight: 48,

    navbarHeight: 64,

    sidebarWidth: 84,

    colorTool: {
        height: 420,
        width: 440,
    },

    infoTool: {
        height: 280,
    },

    opacity: 0.95,
};

const defaultTheme = {
    palette: {
        primary: {
            main: "#0C3C60",
        },
        secondary: {
            main: "#3D3D3D",
        },
        disabled: {
            main: "#9E9E9E",
        },
    },
};

const emptyUser = {
    id: 0,
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    is_superuser: false,
    organizations: [],
};

const histOptions = {
    min: 0,
    max: 10000,
    newHistMinSize: 1000,
};

export {
    histOptions,
    emptyUser,
    navSubsetOfPaths,
    adminNavSubsetOfPaths,
    defaultTheme,
    dynamicPathAffixes,
    customVisuals,
    maxCharLengthInValueCol,
    paths,
    queries,
    numbers,
    urls,
    recolorParamConstants,
    thumbnailConstants,
    pagesWhereItsOKIfOrgContextInvalid,
};
