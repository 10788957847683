import { Box, Typography, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "./Constants.js";
import {
    asyncAuth,
    getOrgInfo,
    isAdmin,
    reverseDynamizeAKAHydratePageFromURL,
    orgContextIsValid,
} from "./helperFuncs.js";
import Navbar from "./Navbar.js";
import OrgBar, { OrgContext } from "./organization.js";
import { AllWrapper, NavWrapper, OrgWrapper } from "./styles.js";
import TransferList from "./transferlist.js";
import useWindowDimensions from "./windowDimensions.js";

const Admin = () => {
    const { height, width } = useWindowDimensions();

    const [refresh, setRefresh] = useState(false);

    const [orgInfo, setOrgInfo] = useState(null);

    const { org, setOrg, allOrgs, setOrgs, user, setUser } =
        useContext(OrgContext);

    const [admin, setAdmin] = useState(true);

    let navigate = useNavigate();

    useEffect(() => {
        if (orgContextIsValid(org, allOrgs, user)) {
            setAdmin(isAdmin(allOrgs, org, user.id));
        }
    }, [org, allOrgs]);

    useEffect(() => {
        if (!admin) {
            navigate({
                pathname: paths.DEFAULT,
            });
        }
    }, [admin]);

    useEffect(() => {
        if (admin && orgContextIsValid(org, allOrgs, user)) {
            getOrgInfo(allOrgs[org].id).then((res) => {
                setOrgInfo(res);
            });
        }
    }, [admin, org, allOrgs, refresh]);

    useEffect(() => {
        asyncAuth()
            .then((authRes) => {
                console.log("authenticated! loading image browser...");
            })
            .catch((err) => {
                console.log("not authenticated! redirecting to login...");
                navigate(paths.LOGIN);
            });
    }, []);

    return (
        <OrgWrapper>
            <OrgBar />
            <NavWrapper>
                <Navbar />
                <AllWrapper>
                    <Box
                        sx={{
                            bgcolor: "background.paper",
                            pt: 8,
                            padding: "0 0 0 24px",
                        }}
                        maxWidth='100vw'
                    >
                        <Typography
                            component='h1'
                            variant='h2'
                            align='left'
                            color='text.primary'
                            gutterBottom
                        >
                            Administration
                        </Typography>
                        <Typography
                            variant='h5'
                            align='left'
                            color='text.secondary'
                            paragraph
                        >
                            Manage organization members and admins.
                        </Typography>
                        {orgInfo !== null ? (
                            <TransferList
                                leftArr={orgInfo.users}
                                rightArr={orgInfo.admins}
                                activeOrgID={orgInfo.id}
                                refresh={() => setRefresh(!refresh)}
                            />
                        ) : (
                            <CircularProgress />
                        )}
                    </Box>
                </AllWrapper>
            </NavWrapper>
        </OrgWrapper>
    );
};

export default Admin;
