import {
    dynamicPathAffixes,
    queries as Queries,
    numbers,
    pagesWhereItsOKIfOrgContextInvalid,
} from "./Constants.js";

require("dotenv").config();

const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

// take a route like /register and turn it into Register
const routeToName = (str) => {
    return toTitleCase(str.substring(str.lastIndexOf("/") + 1));
};

// take a name like Register and turn it into /register
const nameToRoute = (str) => {
    return "/" + str.toLowerCase();
};

// deconstruct the properties in a complex object into a flat array
const propertiesToArray = (obj) => {
    const isObject = (val) =>
        val && typeof val === "object" && !Array.isArray(val);

    const addDelimiter = (a, b) => (a ? `${a}.${b}` : b);

    const paths = (obj = {}, head = "") => {
        return Object.entries(obj).reduce((product, [key, value]) => {
            let fullPath = addDelimiter(head, key);
            return isObject(value)
                ? product.concat(paths(value, fullPath))
                : product.concat(fullPath);
        }, []);
    };

    return paths(obj);
};

const getTokenStrFromStorage = () => {
    let tokenStr = "";
    if (localStorage.getItem("token") !== null) {
        tokenStr = localStorage.getItem("token");
    } else if (sessionStorage.getItem("token") !== null) {
        tokenStr = sessionStorage.getItem("token");
    } else {
        return null;
    }
    return tokenStr;
};

// DEPRECATED: only use when synchronous logic is needed: merely checks to see that a cookie exists until server responds
const syncAuth = () => {
    let tokenStr = getTokenStrFromStorage();
    if (tokenStr === null) {
        return null;
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token " + tokenStr);

    var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    fetch(
        process.env.REACT_APP_BACKEND_URL + "/auth/user_info/",
        requestOptions
    )
        .then((response) => {
            let body = response.json();
            return body;
        })
        .then((result) => {
            return result;
        })
        .catch((error) => {
            return null;
        });
};

// thoroughly checks with server to make sure valid user is logged in
const asyncAuth = async () => {
    return new Promise((resolve, reject) => {
        let tokenStr = getTokenStrFromStorage();
        if (tokenStr === null) {
            console.log("no user currently logged in!");
            reject(null);
        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + tokenStr);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL + "/auth/user_info/",
            requestOptions
        )
            .then((response) => {
                let body = response.json();

                // if still pending
                if (body.status === "pending") {
                    reject(null);
                }

                return body;
            })
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const listAllOrgs = async () => {
    return new Promise((resolve, reject) => {
        let tokenStr = getTokenStrFromStorage();
        if (tokenStr === null) {
            console.log("no user currently logged in!");
            reject(null);
        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + tokenStr);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL + "/api/v1/organizations/",
            requestOptions
        )
            .then((response) => {
                let body = response.json();
                return body;
            })
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const getOrgInfo = (orgId) => {
    return new Promise((resolve, reject) => {
        let tokenStr = getTokenStrFromStorage();
        if (tokenStr === null) {
            console.log("no user currently logged in!");
            reject(null);
        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + tokenStr);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL +
                "/api/v1/organization?organization=" +
                orgId,
            requestOptions
        )
            .then((response) => {
                let body = response.json();
                return body;
            })
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const clearStorage = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
};

const logoutFromServer = () => {
    let tokenStr = getTokenStrFromStorage();
    if (tokenStr === null) {
        return null;
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token " + tokenStr);

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
    };

    fetch(process.env.REACT_APP_BACKEND_URL + "/auth/logout/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
            console.log("logged out");
            return result;
        })
        .catch((error) => {
            console.log("error", error);
            return null;
        });
};

const formatDate = (date) => {
    // Date format is provided as YYYY-MM-DDTHH:MM:SSZ
    // Want to return HH:MM AM/PM on MM/DD/YYYY
    const year = date.substring(0, 4);
    const month = date.substring(5, 7);
    const day = date.substring(8, 10);
    const hour = date.substring(11, 13);
    const minute = date.substring(14, 16);
    const second = date.substring(17, 19);
    const ampm = hour >= 12 ? "PM" : "AM";

    return `${hour % 12}:${minute} ${ampm} on ${month}/${day}/${year}`;
};

const formatDateAndTime = (date) => {
    // // return date.substring(0, 10) + " " + date.substring(11, 16);
    // var localDate = new Date(date);
    // let dateStr = localDate.toString();
    // return (
    //     dateStr.substring(0, dateStr.indexOf("GMT")) +
    //     " " +
    //     dateStr.substring(dateStr.indexOf("GMT") + 8, dateStr.indexOf("("))
    // );
    const localDate = new Date(date);
  
    const timeOptions = {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    };

    const dateOptions = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    };

  const timeString = new Intl.DateTimeFormat('en-US', timeOptions).format(localDate);
  const dateString = new Intl.DateTimeFormat('en-US', dateOptions).format(localDate);

  return `${timeString} on ${dateString}`;
};

const removeQueryParams = () => {
    if (history.pushState) {
        var newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
        window.history.pushState({ path: newurl }, "", newurl);
    }
};

const refreshPage = () => {
    if (window.location.href.includes(Queries.REFRESH_TRIGGER)) {
        removeQueryParams();
        window.location.reload(false);
    }
};

const forceRefreshPage = () => {
    window.location.reload(false);
};

const getProfPic = (fName, lName) => {
    return `https://ui-avatars.com/api/?name=${fName}+${lName}`;
};

const getIconPic = (orgName) => {
    let newName = orgName.replaceAll(" ", "+");
    return `https://ui-avatars.com/api/?name=${newName}`;
};

const getCurrentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
};

const emailValidation = (email) => {
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const urlValidation = (url) => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name and extension
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?" + // port
            "(\\/[-a-z\\d%_.~+]*)*" + // path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(url);
};

const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
};

const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
};

const stringifyObjectVals = (obj) => {
    let newObj = {};
    for (let key in obj) {
        newObj[key] = "" + obj[key];
    }
    return newObj;
};

const numberifyObjectVals = (obj) => {
    let newObj = {};
    for (let key in obj) {
        newObj[key] = Number(obj[key]);
    }
    return newObj;
};

const loadTags = async (orgID) => {
    // Promise to return tags
    return new Promise((resolve, reject) => {
        // load all available tags from server

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL +
                "/api/v1/get_tags/?organization=" +
                orgID,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                resolve(result.tags);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const deleteTag = (tagVal) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

    var formdata = new FormData();
    formdata.append("tag", tagVal);

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
    };

    fetch(
        process.env.REACT_APP_BACKEND_URL + "/api/v1/delete_tag/",
        requestOptions
    )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
};

const destructureTags = (tags) => {
    let newTags = [];
    // iterate through tags
    for (let i = 0; i < tags.length; i++) {
        newTags.push(tags[i].name);
    }
    return newTags;
};

const calculateTagsDelta = (oldTags, newTags) => {
    let delta = {
        "tags added": [],
        "tags removed": [],
    };

    // iterate through old tags
    for (let i = 0; i < oldTags.length; i++) {
        // if tag is not in new tags
        if (!newTags.includes(oldTags[i])) {
            delta["tags removed"].push(oldTags[i]);
        }
    }
    // iterate through new tags
    for (let i = 0; i < newTags.length; i++) {
        // if tag is not in old tags
        if (!oldTags.includes(newTags[i])) {
            delta["tags added"].push(newTags[i]);
        }
    }

    return delta;
};

const deleteImage = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        id: id,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    fetch(
        process.env.REACT_APP_BACKEND_URL + "/api/v1/delete_image/",
        requestOptions
    )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
};

const isAdmin = (usersOrgs, activeOrg, userID) => {
    console.log("wtf", usersOrgs, activeOrg, userID);
    return usersOrgs[activeOrg].role === "admin";
};

const logout = () => {
    logoutFromServer();
    clearStorage();
};

const listOfObjsIncludeOneWMatchingID = (listOfObjs, id) => {
    for (let i = 0; i < listOfObjs.length; i++) {
        if (listOfObjs[i].id === id) {
            return true;
        }
    }
    return false;
};

const stripOrgsDown = (orgs, userID) => {
    let newOrgs = [];
    for (let i = 0; i < orgs.length; i++) {
        let role = listOfObjsIncludeOneWMatchingID(orgs[i].admins, userID)
            ? "admin"
            : listOfObjsIncludeOneWMatchingID(orgs[i].users, userID)
            ? "user"
            : "none";
        if (role !== "none") {
            newOrgs.push({
                id: orgs[i].id,
                name: orgs[i].name,
                description: orgs[i].description,
                role: role,
            });
        }
    }
    return newOrgs;
};

const stripAllOrgsDownAsAdmin = (orgs, userID) => {
    let newOrgs = [];
    for (let i = 0; i < orgs.length; i++) {
        newOrgs.push({
            id: orgs[i].id,
            name: orgs[i].name,
            description: orgs[i].description,
            role: "admin",
        });
    }
    return newOrgs;
};

const dynamizeURL = (url, newOrg, newViewId) => {
    let newURL = url + "";
    if (newViewId !== undefined) {
        newURL = newURL.replaceAll(
            ":" + dynamicPathAffixes.imgID,
            newViewId + ""
        );
    }
    if (newOrg !== undefined) {
        newURL = newURL.replaceAll(":" + dynamicPathAffixes.orgID, newOrg + "");
    }
    return newURL;
};

const arrayIsEmpty = (arr) => {
    if (arr.length === 0) {
        return true;
    } else {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] !== undefined && arr[i] !== null) {
                return false;
            }
        }
        return true;
    }
};

const getAllSuperusers = () => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL + "/api/v1/set_superuser/",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                console.log("error", error);
                reject(error);
            });
    });
};

const deleteSuperuser = (userEmail) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var formdata = new FormData();
        formdata.append("email", userEmail);

        var requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL + "/api/v1/set_superuser/",
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                console.log("error", error);
                reject(error);
            });
    });
};

const addSuperuser = (userEmail) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var formdata = new FormData();
        formdata.append("email", userEmail);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL + "/api/v1/set_superuser/",
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                resolve(result);
            })
            .catch((error) => {
                console.log("error", error);
                reject(error);
            });
    });
};

const createOrg = (orgName, orgDescription) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var formdata = new FormData();
        formdata.append("name", orgName);
        formdata.append("description", orgDescription);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL + "/api/v1/organizations/",
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => resolve(result))
            .catch((error) => {
                console.log("error", error);
                reject(error);
            });
    });
};

const remoteDemote = (userID, orgID) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

    var formdata = new FormData();
    formdata.append("user", userID + "");
    formdata.append("organization", orgID + "");

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
    };

    fetch(
        process.env.REACT_APP_BACKEND_URL +
            "/api/v1/demote_admin_from_organization/",
        requestOptions
    )
        .then((response) => response.text())
        .then((result) => {
            console.log(result);
        })
        .catch((error) => {
            console.log("error", error);
        });
};

const remotePromote = (userID, orgID) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

    var formdata = new FormData();
    formdata.append("user", userID + "");
    formdata.append("organization", orgID + "");

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
    };

    fetch(
        process.env.REACT_APP_BACKEND_URL +
            "/api/v1/add_admin_to_organization/",
        requestOptions
    )
        .then((response) => response.text())
        .then((result) => {
            console.log(result);
        })
        .catch((error) => {
            console.log("error", error);
        });
};

const remoteRemove = (userID, orgID, isAdmin) => {
    if (isAdmin) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var formdata = new FormData();
        formdata.append("user", userID + "");
        formdata.append("organization", orgID + "");

        var requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL +
                "/api/v1/add_admin_to_organization/",
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                console.log(result);
            })
            .catch((error) => {
                console.log("error", error);
            });
    } else {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var formdata = new FormData();
        formdata.append("user", userID + "");
        formdata.append("organization", orgID + "");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL +
                "/api/v1/remove_member_from_organization/",
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                console.log(result);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
};

const remoteAdd = (userEmail, orgID, asAdmin) => {
    if (asAdmin) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var formdata = new FormData();
        formdata.append("email", userEmail + "");
        formdata.append("organization", orgID + "");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL +
                "/api/v1/add_admin_to_organization/",
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                console.log(result);
            })
            .catch((error) => {
                console.log("error", error);
            });
    } else {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var formdata = new FormData();
        formdata.append("email", userEmail + "");
        formdata.append("organization", orgID + "");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL +
                "/api/v1/add_member_to_organization/",
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                console.log(result);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
};

const remoteGetHists = (imageID) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL +
                "/api/v1/histomics/?image_id=" +
                imageID,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                resolve(result.histomics);
            })
            .catch((error) => {
                console.log("error", error);
                reject(error);
            });
    });
};

const filterObjsInArrayByAttributeRange = (
    array,
    attribute,
    acceptedMin,
    acceptedMax,
    exceptionParam,
    exceptionValue
) => {
    return array
        .filter((obj) => {
            return (
                (parseInt(obj[attribute]) >= parseInt(acceptedMin) &&
                    parseInt(obj[attribute]) <= parseInt(acceptedMax)) ||
                obj[exceptionParam] === exceptionValue
            );
        })
        .reverse();
};

const filterObjsInArrayByNotThisAttribute = (
    array,
    attribute,
    acceptedValue
) => {
    console.log(array);
    return array.filter((obj) => {
        return obj[attribute] !== acceptedValue;
    });
};

const arrayIncludesObjWithAttribute = (array, attribute, value) => {
    return array.some((obj) => {
        return obj[attribute] === value;
    });
};

const atLeastOneStringInArrayIsASubstringOf = (array, string) => {
    return array.some((substring) => {
        return string.includes(substring);
    });
};

const orgContextIsValid = (org, allOrgs, user) => {
    return (
        allOrgs.length !== 0 ||
        atLeastOneStringInArrayIsASubstringOf(
            pagesWhereItsOKIfOrgContextInvalid,
            window.location.href
        )
    );
};

const getNumberOfSlashes = (string) => {
    return string.match(/\//g).length;
};

const infoNecessaryToReverseDynamizePage = (path) => {
    const nestLevel = getNumberOfSlashes(path);
    switch (nestLevel) {
        case 1: // account or superuser page
            return {
                status: "as of now, unused",
            };
        case 2: // dashboard, upload, or admin page within an org
            return {
                orgID: path.split("/")[1],
                subPage: path.split("/")[2],
            };
        case 3: // image viewing page
            return {
                orgID: path.split("/")[1],
                subPage: path.split("/")[2],
                imageID: path.split("/")[3],
            };
        default: // invalid
            return {
                status: "invalid",
            };
    }
};

const getIndexOfOrgWithID = (orgID, allOrgs) => {
    return allOrgs.forEach((org, index) => {
        if (org.id === orgID) {
            return index;
        }
    });
};

const reverseDynamizeAKAHydratePageFromURL = (currentOrg, setNewOrg) => {
    return new Promise((resolve, reject) => {
        try {
            const timer = setTimeout(() => {
                const reverseDynamizingInfo =
                    infoNecessaryToReverseDynamizePage(
                        new URL(window.location.href).pathname
                    );

                if (reverseDynamizingInfo.orgID !== currentOrg) {
                    setNewOrg(reverseDynamizingInfo.orgID);

                    resolve("Set org to " + reverseDynamizingInfo.orgID);
                } else {
                    resolve(
                        "Org already set to " + reverseDynamizingInfo.orgID
                    );
                }
            }, numbers.DYNAMIZE_DELAY);
        } catch (error) {
            reject(error);
        }
    });
};

export {
    reverseDynamizeAKAHydratePageFromURL,
    getIndexOfOrgWithID,
    infoNecessaryToReverseDynamizePage,
    orgContextIsValid,
    filterObjsInArrayByNotThisAttribute,
    arrayIncludesObjWithAttribute,
    filterObjsInArrayByAttributeRange,
    remoteGetHists,
    createOrg,
    deleteSuperuser,
    getAllSuperusers,
    addSuperuser,
    remoteDemote,
    remotePromote,
    remoteRemove,
    remoteAdd,
    getOrgInfo,
    forceRefreshPage,
    arrayIsEmpty,
    dynamizeURL,
    stripAllOrgsDownAsAdmin,
    stripOrgsDown,
    logout,
    listAllOrgs,
    isAdmin,
    getIconPic,
    deleteImage,
    calculateTagsDelta,
    destructureTags,
    deleteTag,
    loadTags,
    blobToBase64,
    getKeyByValue,
    emailValidation,
    urlValidation,
    getProfPic,
    getCurrentDate,
    removeQueryParams,
    refreshPage,
    toTitleCase,
    routeToName,
    nameToRoute,
    propertiesToArray,
    syncAuth,
    clearStorage,
    logoutFromServer,
    getTokenStrFromStorage,
    asyncAuth,
    formatDate,
    formatDateAndTime,
    stringifyObjectVals,
    numberifyObjectVals,
};
