import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { paths } from "./Constants.js";
import Copyright from "./copyright.js";

const NotFound = () => {
    let navigate = useNavigate();

    return (
        <>
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                }}
            >
                <Container>
                    <Typography
                        component="h1"
                        variant="h2"
                        align="left"
                        color="text.primary"
                        gutterBottom
                    >
                        Error 404
                    </Typography>
                    <Typography
                        variant="h5"
                        align="left"
                        color="text.secondary"
                        paragraph
                    >
                        Page Not Found
                    </Typography>
                    <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="le"
                    >
                        <Button
                            variant="contained"
                            onClick={() => navigate(paths.DEFAULT)}
                        >
                            Back to Safety
                        </Button>
                        {/* <Button variant="outlined" onClick={() => window.location="https://tinyurl.com/rmx4rjyt"} >I'm feeling adventurous...</Button> */}
                    </Stack>
                </Container>
            </Box>
            <Box
                component="footer"
                sx={{
                    py: 3,
                    px: 2,
                    mt: "auto",
                    position: "fixed",
                    bottom: 0,
                    width: "100vw",
                    backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                            ? theme.palette.grey[200]
                            : theme.palette.grey[800],
                }}
            >
                <Container maxWidth="sm">
                    <Copyright />
                </Container>
            </Box>
        </>
    );
};

export default NotFound;
