import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DeleteOutline, PlayCircle } from "@mui/icons-material";
import {
    Tooltip,
    Badge,
    Box,
    Button,
    Chip,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { defaultTheme, paths, queries } from "./Constants.js";
import {
    arrayIsEmpty,
    asyncAuth,
    deleteTag,
    dynamizeURL,
    getTokenStrFromStorage,
    loadTags,
    orgContextIsValid,
    reverseDynamizeAKAHydratePageFromURL,
} from "./helperFuncs.js";
import Navbar from "./Navbar.js";
import OrgBar, { OrgContext } from "./organization.js";
import { AllWrapper, NavWrapper, OrgWrapper } from "./styles.js";

require("dotenv").config();

const SubmitArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
`;

const Divider = styled.div`
    height: 1.5em;
`;

const HorizontalScroll = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 20px;
    max-width: 100%;
    padding: 10px 0 0 0;
`;

const Upload = (props) => {
    let navigate = useNavigate();

    const { org, setOrg, allOrgs, setOrgs, user, setUser } =
        useContext(OrgContext);

    const [allTags, setAllTags] = useState([]);
    const [newTag, setNewTag] = useState("");

    const [imgNames, setImgNames] = useState([]);
    const [imgTags, setImgTags] = useState([]); // keep in mind this is 2D: [imgIdx][tagIdx]
    const [imgDescs, setImgDescs] = useState([]);
    const [imgFiles, setImgFiles] = useState([]);

    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        asyncAuth()
            .then((authRes) => {
                console.log("authenticated! loading upload page...");
            })
            .catch((err) => {
                console.log("not authenticated! redirecting to login...");
                navigate(paths.LOGIN);
            });
    }, []);

    useEffect(() => {
        if (orgContextIsValid(org, allOrgs, user)) {
            loadTags(allOrgs[org].id).then((tags) => {
                setAllTags(tags);
            });
        }
    }, []);

    const handleImgTagsChange = (value, index) => {
        // Value itself is an array of the current selected tags at specified index
        setImgTags([
            ...imgTags.slice(0, index),
            value,
            ...imgTags.slice(index + 1),
        ]);
    };

    const removeTile = (index) => {
        setImgNames([
            ...imgNames.slice(0, index),
            ...imgNames.slice(index + 1),
        ]);
        setImgTags([...imgTags.slice(0, index), ...imgTags.slice(index + 1)]);
        setImgDescs([
            ...imgDescs.slice(0, index),
            ...imgDescs.slice(index + 1),
        ]);
        setImgFiles([
            ...imgFiles.slice(0, index),
            ...imgFiles.slice(index + 1),
        ]);
    };

    const handleImgTagRemoval = (value, index) => {
        let newAllTags = [];
        for (let i = 0; i < allTags.length; i++) {
            if (allTags[i] !== value) {
                newAllTags.push(allTags[i]);
            }
        }

        setAllTags(newAllTags);

        let newArr = [];
        for (let i = 0; i < imgTags[index].length; i++) {
            if (imgTags[index][i] !== value) {
                newArr.push(imgTags[index][i]);
            }
        }

        setImgTags([
            ...imgTags.slice(0, index),
            newArr,
            ...imgTags.slice(index + 1),
        ]);
        deleteTag(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        for (let i = 0; i < imgFiles.length; i++) {
            if (
                imgFiles[i] === "" ||
                imgFiles[i] === undefined ||
                imgFiles[i] === null
            ) {
                continue;
            } else {
                var myHeaders = new Headers();
                myHeaders.append(
                    "Authorization",
                    "Token " + getTokenStrFromStorage()
                );

                var formdata = new FormData();
                formdata.append("image", imgFiles[i], imgFiles[i].name);
                formdata.append("description", imgDescs[i]);
                formdata.append("image_name", imgNames[i]);

                // attach tags to formdata
                for (let j = 0; j < imgTags[i].length; j++) {
                    formdata.append("tags", imgTags[i][j]);
                }

                formdata.append("organization", allOrgs[org].id);

                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: formdata,
                    redirect: "follow",
                };

                fetch(
                    process.env.REACT_APP_BACKEND_URL + "/api/v1/upload_image/",
                    requestOptions
                )
                    .then((response) => response.json())
                    .then((result) => {
                        if (imgFiles.length - 1 === i) {
                            setUploading(false);

                            navigate({
                                pathname: dynamizeURL(paths.BROWSER, org),
                                search: queries.UPLOAD_SUCCESS,
                            });
                        }
                    })
                    .catch((error) => console.log("error", error));
            }
        }
    };

    return (
        <OrgWrapper>
            <OrgBar />
            <NavWrapper>
                <Navbar />
                <AllWrapper>
                    <Box
                        sx={{
                            bgcolor: "background.paper",
                            pt: 8,
                            padding: "0 0 0 24px",
                        }}
                        maxWidth='100vw'
                    >
                        <Typography
                            component='h1'
                            variant='h2'
                            align='left'
                            color='text.primary'
                            gutterBottom
                        >
                            Upload Images
                        </Typography>
                        <Typography
                            variant='h5'
                            align='left'
                            color='text.secondary'
                            paragraph
                        >
                            For each, attach a name, tags, and a description.
                        </Typography>
                        <Divider />
                        <HorizontalScroll>
                            {imgNames.map((name, index) => (
                                <Badge
                                    badgeContent={"X"}
                                    color='primary'
                                    sx={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        removeTile(index);
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            gap: "25px",
                                            padding: "25px",
                                            minWidth: "400px",
                                            minHeight: "500px",
                                            backgroundColor: "#F0F0F0",
                                            borderRadius: "10px",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <TextField
                                            key={index + "A"}
                                            id='filled-basic'
                                            label='Image Name'
                                            variant='outlined'
                                            value={imgNames[index]}
                                            onChange={(e) => {
                                                setImgNames([
                                                    ...imgNames.slice(0, index),
                                                    e.target.value,
                                                    ...imgNames.slice(
                                                        index + 1
                                                    ),
                                                ]);
                                            }}
                                            autoFocus
                                            sx={{
                                                backgroundColor: "#FFFFFF",
                                            }}
                                        />
                                        <FormControl
                                            fullWidth
                                            sx={{
                                                backgroundColor: "#FFFFFF",
                                            }}
                                        >
                                            <InputLabel id='demo-multiple-chip-label'>
                                                Tags
                                            </InputLabel>
                                            <Select
                                                labelId='demo-multiple-chip-label'
                                                id='demo-multiple-chip'
                                                multiple
                                                value={imgTags[index]}
                                                onChange={(e) =>
                                                    handleImgTagsChange(
                                                        e.target.value,
                                                        index
                                                    )
                                                }
                                                input={
                                                    <OutlinedInput
                                                        id='select-multiple-chip'
                                                        label='Chip'
                                                    />
                                                }
                                                renderValue={(selected) => {
                                                    return (
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexWrap:
                                                                    "wrap",
                                                                gap: 0.5,
                                                            }}
                                                        >
                                                            {selected.map(
                                                                (value) => (
                                                                    <Chip
                                                                        key={
                                                                            value
                                                                        }
                                                                        label={
                                                                            value
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </Box>
                                                    );
                                                }}
                                            >
                                                {allTags.map((tag) => (
                                                    <MenuItem
                                                        key={tag}
                                                        value={tag}
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <Typography fullWidth>
                                                            {tag}
                                                        </Typography>

                                                        <Tooltip
                                                            title={
                                                                "Delete tag globally"
                                                            }
                                                            arrow
                                                            placement='right'
                                                        >
                                                            <IconButton aria-label='delete'>
                                                                <DeleteOutline
                                                                    fontSize='small'
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        handleImgTagRemoval(
                                                                            tag,
                                                                            index
                                                                        );
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </MenuItem>
                                                ))}
                                                <MenuItem>
                                                    <TextField
                                                        key={index + "-newtag"}
                                                        id='filled-basic'
                                                        label='Create New Tag'
                                                        variant='outlined'
                                                        size='small'
                                                        fullWidth
                                                        value={newTag}
                                                        onChange={(e) => {
                                                            setNewTag(
                                                                e.target.value
                                                            );
                                                        }}
                                                        onKeyDown={(e) => {
                                                            e.stopPropagation();
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                setAllTags([
                                                                    ...allTags,
                                                                    newTag,
                                                                ]);
                                                                setNewTag("");
                                                                handleImgTagsChange(
                                                                    [
                                                                        ...imgTags[
                                                                            index
                                                                        ],
                                                                        newTag,
                                                                    ],
                                                                    index
                                                                );
                                                            }
                                                        }}
                                                        autoFocus
                                                        sx={{
                                                            backgroundColor:
                                                                "#FFFFFF",
                                                        }}
                                                    />
                                                    <IconButton aria-label='add'>
                                                        <PlayCircle
                                                            fontSize='large'
                                                            color='primary'
                                                            onClick={() => {
                                                                setAllTags([
                                                                    ...allTags,
                                                                    newTag,
                                                                ]);
                                                                setNewTag("");
                                                                handleImgTagsChange(
                                                                    [
                                                                        ...imgTags[
                                                                            index
                                                                        ],
                                                                        newTag,
                                                                    ],
                                                                    index
                                                                );
                                                            }}
                                                        />
                                                    </IconButton>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            key={index + "B"}
                                            id='filled-textarea'
                                            label='Description'
                                            placeholder='Describe your image and make it easier to find later.'
                                            multiline
                                            variant='outlined'
                                            minRows={10}
                                            value={imgDescs[index]}
                                            onChange={(e) => {
                                                setImgDescs([
                                                    ...imgDescs.slice(0, index),
                                                    e.target.value,
                                                    ...imgDescs.slice(
                                                        index + 1
                                                    ),
                                                ]);
                                            }}
                                            autoFocus
                                            sx={{
                                                backgroundColor: "#FFFFFF",
                                            }}
                                        />
                                        <input
                                            key={index + "C"}
                                            accept='image/*'
                                            id='raised-button-file'
                                            type='file'
                                            onChange={(e) => {
                                                setImgFiles([
                                                    ...imgFiles.slice(0, index),
                                                    e.target.files[0],
                                                    ...imgFiles.slice(
                                                        index + 1
                                                    ),
                                                ]);
                                            }}
                                        />
                                    </Box>
                                </Badge>
                            ))}
                            <Button
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "25px",
                                    padding: "25px",
                                    minWidth: "400px",
                                    minHeight: "500px",
                                    borderRadius: "10px",
                                    border:
                                        "1px solid " +
                                        defaultTheme.palette.primary.main,
                                    fontSize: "100px",
                                }}
                                onClick={() => {
                                    setImgNames([...imgNames, ""]);
                                    setImgTags([...imgTags, []]);
                                    setImgDescs([...imgDescs, ""]);
                                    setImgFiles([...imgFiles, null]);
                                }}
                            >
                                +
                            </Button>
                        </HorizontalScroll>
                        <Divider />
                        <SubmitArea>
                            {uploading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    variant='contained'
                                    sx={{
                                        minWidth: "200px",
                                    }}
                                    onClick={(e) => {
                                        if (arrayIsEmpty(imgFiles)) {
                                            e.preventDefault();
                                            alert(
                                                "Please add at least one image."
                                            );
                                        } else {
                                            setUploading(true);
                                            handleSubmit(e);
                                        }
                                    }}
                                >
                                    Upload Images
                                </Button>
                            )}
                        </SubmitArea>
                    </Box>
                </AllWrapper>
            </NavWrapper>
        </OrgWrapper>
    );
};

export default Upload;
