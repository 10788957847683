import * as React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
    adminNavSubsetOfPaths,
    customVisuals,
    defaultTheme,
    navSubsetOfPaths,
    paths,
    queries,
} from "./Constants.js";
import {
    dynamizeURL,
    isAdmin,
    routeToName,
    toTitleCase,
    orgContextIsValid,
    reverseDynamizeAKAHydratePageFromURL,
    refreshPage,
} from "./helperFuncs.js";
import { OrgContext } from "./organization.js";
import useWindowDimensions, { isMobile } from "./windowDimensions.js";

const NavStyle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: ${customVisuals.navbarHeight};
    width: 100vw;
    background-color: ${defaultTheme.palette.secondary.main};
    align-items: center;

    position: fixed;
`;

const NavElementStyle = styled(Link)`
    padding: 12px 24px;
    text-decoration: none;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    transition: all ${customVisuals.transitionSpeed}s;
    background-color: ${(props) => (props.primary ? "white" : "transparent")};
    color: ${(props) =>
        props.primary ? defaultTheme.palette.primary.main : "default"};
    transition: all 0.24s;
    &:hover {
        background-color: ${(props) =>
            props.primary ? "white" : "rgba(0, 0, 0, 0.24)"};
    }
`;

const isActive = (path) => {
    return window.location.pathname === path;
};

// optional: isAdmin, isMobile (overrides local calculation)
const Navbar = (props) => {
    const [mobile, setMobile] = React.useState(false);
    const [admin, setAdmin] = React.useState(false);

    const { height, width } = useWindowDimensions();
    const { org, setOrg, allOrgs, setOrgs, user, setUser } =
        React.useContext(OrgContext);

    let navigate = useNavigate();

    useEffect(() => {
        if (props.isMobile) {
            setMobile(true);
        } else {
            setMobile(isMobile(width));
        }
        if (props.isAdmin) {
            setAdmin(true);
        } else {
            if (orgContextIsValid(org, allOrgs, user)) {
                // for some reason, this is occasionally erroneous
                try {
                    setAdmin(isAdmin(allOrgs, org, user.id));
                } catch (e) {
                    console.log("error in navbar.js: ", e);
                    window.location.reload(false);
                }
            }
        }
    }, [props.isMobile, props.isAdmin, org, allOrgs]);

    let links = [];

    if (!mobile && admin) {
        adminNavSubsetOfPaths.forEach((key) => {
            let path = paths[key];
            let orgPath = dynamizeURL(path, org);
            links.push(
                <NavElementStyle to={orgPath} primary={isActive(orgPath)}>
                    {toTitleCase(routeToName(path))}
                </NavElementStyle>
            );
        });
    } else {
        navSubsetOfPaths.forEach((key) => {
            let path = paths[key];
            let orgPath = dynamizeURL(path, org);
            links.push(
                <NavElementStyle to={orgPath} primary={isActive(orgPath)}>
                    {toTitleCase(routeToName(path))}
                </NavElementStyle>
            );
        });
    }

    useEffect(() => {
        reverseDynamizeAKAHydratePageFromURL(org, setOrg);
    }, []);

    return (
        <NavStyle>
            {links.map((link, index) => (
                <>{link}</>
            ))}
        </NavStyle>
    );
};
export default Navbar;
