import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Account from "./Account.js";
import Admin from "./Admin.js";
import Browser from "./Browser.js";
import Dashboard from "./Dashboard.js";
import Default from "./Default.js";
import GodView from "./GodView.js";
import Login from "./Login.js";
import NotFound from "./NotFound.js";
import Signup from "./Signup.js";
import Upload from "./Upload.js";
import Viewer from "./Viewer.js";
import styled from "styled-components";

import { defaultTheme, emptyUser, paths } from "./Constants.js";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { OrgContext } from "./organization.js";
import RedirectHub from "./RedirectHub.js";

require("dotenv").config();

const theme = createTheme(defaultTheme);

export default function App() {
    const [org, setOrg] = React.useState(0);
    const [allOrgs, setOrgs] = React.useState([]);
    const [user, setUser] = React.useState(emptyUser);
    const value = {
        org: org,
        setOrg: setOrg,
        allOrgs: allOrgs,
        setOrgs: setOrgs,
        user: user,
        setUser: setUser,
    };

    return (
        <ThemeProvider theme={theme}>
            <OrgContext.Provider value={value}>
                <Routes>
                    <Route path={paths.DASHBOARD} element={<Dashboard />} />
                    <Route path={paths.BROWSER} element={<Browser />} />
                    <Route path={paths.UPLOAD} element={<Upload />} />
                    <Route path={paths.ACCOUNT} element={<Account />} />
                    <Route path={paths.SIGNUP} element={<Signup />} />
                    <Route path={paths.LOGIN} element={<Login />} />
                    <Route path={paths.VIEWER} element={<Viewer />} />
                    <Route path={paths.ADMIN} element={<Admin />} />
                    <Route path={paths.GODVIEW} element={<GodView />} />
                    <Route path={paths.DEFAULT} element={<Default />} />
                    <Route path={paths.CATCHALL} element={<NotFound />} />
                    <Route path={paths.REDIRECTHUB} element={<RedirectHub />} />
                </Routes>
            </OrgContext.Provider>
        </ThemeProvider>
    );
}
