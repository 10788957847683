import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import styled from "styled-components";
import {
    Visibility,
    Search,
    RadioButtonUnchecked,
    Delete,
    Close,
    CheckCircle,
} from "@mui/icons-material";
import {
    IconButton,
    Modal,
    CircularProgress,
    Typography,
    TextField,
    Snackbar,
    Select,
    MenuItem,
    LinearProgress,
    InputAdornment,
    Grid,
    FormControl,
    Container,
    Chip,
    CardMedia,
    CardContent,
    CardActions,
    Card,
    Button,
    Box,
    Alert,
} from "@mui/material";
import { numbers, paths, queries, urls } from "./Constants.js";
import {
    asyncAuth,
    deleteImage,
    destructureTags,
    dynamizeURL,
    filterObjsInArrayByNotThisAttribute,
    formatDate,
    getCurrentDate,
    getTokenStrFromStorage,
    isAdmin,
    loadTags,
    removeQueryParams,
    orgContextIsValid,
} from "./helperFuncs.js";
import Navbar from "./Navbar.js";
import OrgBar, { OrgContext } from "./organization.js";
import { AllWrapper, NavWrapper, OrgWrapper } from "./styles.js";
import useWindowDimensions, {
    isMobile,
    MOBILE_WIDTH,
} from "./windowDimensions.js";
import ContextMenu from "./contextmenu.js";

require("dotenv").config();

const ModalContentWrapper = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: space-between;
    align-items: left;
`;

const ActionArea = styled(Box)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 48px 24px 64px 24px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        padding: 16px 16px 0 0;
    }
`;

const TagTitleArea = styled(Box)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 48px 24px 12px 24px;

    @media (max-width: ${MOBILE_WIDTH}px) {
        padding: 16px 16px 0 0;
    }
`;

const MainColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: ${MOBILE_WIDTH}px) {
        width: 72vw;
    }
    @media (max-width: ${MOBILE_WIDTH}px) {
        width: 96%;
    }
`;

const SideColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 24vw;
    padding: 0px 15px 0px 15px;
    height: 100%;
    border-left: 1px solid #e0e0e0;
`;

const DateLine = styled(Typography)`
    font-weight: bold;
    margin: 5px 0 5px 0;
`;

const ButtonRow = styled(CardActions)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const OptionsContainer = styled(Container)`
    max-width: 250px;
`;

const EnterContainer = styled(Container)`
    max-width: 100px;
    margin: 0;
    padding: 0;
    height: 100%;
`;

const EnterButton = styled(Button)`
    height: 55px;
`;

const ClearQuery = styled(IconButton)`
    margin-right: 0px;
`;

const CardThumbnail = styled(CardMedia)`
    height: 250px;
`;

const OneLine = styled.div`
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ColumnHandler = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
    width: 100%;
`;

const TagStack = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px;

    // no wrapping, just horizontal scrolling for overflow
    @media (max-width: ${MOBILE_WIDTH}px) {
        flex-direction: row;
        margin: 24px 0 24px 16px;
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
        flex-wrap: nowrap;
    }
`;

const NoTagsFound = styled(Typography)`
    font-weight: 100;
    font-size: 16px;
    color: grey;
    margin: 16px;
`;

const CardStack = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin: 7px 0 14px 0;
    overflow-x: auto;
    flex-wrap: nowrap;
`;

const Browser = (props) => {
    const { height, width } = useWindowDimensions();

    const [processingCards, setProcessingCards] = useState([]);
    const [cards, setCards] = useState([]);

    const [reload, setReload] = useState(false); // stream 1: hooks to update lists3, getTags
    const [reloadCurrentlyUploading, setReloadCurrentlyUploading] =
        useState(false); // stream 2: hooks for listUploading

    const [tags, setTags] = useState([]);
    const [tagActivenesses, setTagActivenesses] = useState([]); // parallel array of booleans indicating whether a tag is active

    const { org, setOrg, allOrgs, setOrgs, user, setUser } =
        useContext(OrgContext);

    const [admin, setAdmin] = React.useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        if (orgContextIsValid(org, allOrgs, user)) {
            setAdmin(isAdmin(allOrgs, org, user.id));
        }
    }, [org, allOrgs]);

    useEffect(() => {
        asyncAuth()
            .then((authRes) => {
                console.log("authenticated! loading image browser...");
            })
            .catch((err) => {
                console.log("not authenticated! redirecting to login...");
                navigate(paths.LOGIN);
            });
    }, []);

    useEffect(() => {
        if (allOrgs.length !== 0) {
            loadTags(allOrgs[org].id).then((tags) => {
                setTags(tags);
                // for every tag in the result, set the activeness to true
                let tagActivenesses = [];
                for (let i = 0; i < tags.length; i++) {
                    tagActivenesses.push(false);
                }
                setTagActivenesses(tagActivenesses);
            });
        }
    }, [reload]);

    useEffect(() => {
        if (orgContextIsValid(org, allOrgs, user)) {
            // load complete images from server

            var myHeaders = new Headers();
            myHeaders.append(
                "Authorization",
                "Token " + getTokenStrFromStorage()
            );

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            fetch(
                process.env.REACT_APP_BACKEND_URL +
                    "/api/v1/list_s3_images_short/?organization=" +
                    allOrgs[org].id,
                requestOptions
            )
                .then((response) => {
                    return response.json();
                })
                .then((result) => {
                    let newCards = filterObjsInArrayByNotThisAttribute(
                        result.organization_images,
                        "deleting",
                        true
                    );
                    console.log("====================");
                    console.log(newCards);
                    setCards(newCards);
                })
                .catch((error) => {
                    console.log("error", error);
                });
        }
    }, [reload]);

    useEffect(() => {
        // load currently-processing images from server

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL +
                "/api/v1/list_uploading_images/",
            requestOptions
        )
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                setProcessingCards(result.incomplete);

                // While images are still processing, update the frontend every n seconds
                if (result.incomplete.length > 0) {
                    setTimeout(function () {
                        setReloadCurrentlyUploading(!reloadCurrentlyUploading);
                    }, numbers.PING_DELAY);
                } else {
                    setReload(!reload); // when done, update stream 1 only once
                }
            })
            .catch((error) => {
                console.log("error", error);
            });

        // To test the frontend's rendering of current processing images, uncomment the following line:
        // let exArray = [];
        // exArray.push(exampleProcessingCard);
        // setTimeout(function() { setProcessingCards(exArray); }, 2000);
    }, [reloadCurrentlyUploading]);

    const [searchQuery, setSearchQuery] = useState("");
    const [activeSearchQuery, setActiveSearchQuery] = useState("");
    const [sortOrder, setSortOrder] = useState(1);

    const [modalOpen, setModalOpen] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(-1);

    const [showUploadAlert, setShowUploadAlert] = useState(
        window.location.href.includes(queries.UPLOAD_SUCCESS)
    );

    if (showUploadAlert) {
        setTimeout(function () {
            removeQueryParams();
            setShowUploadAlert(false);
        }, numbers.ALERT_TIMEOUT); // remove the alert after 6 seconds
    }

    const handleOpen = (id) => {
        setImageToDelete(id);
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
        setImageToDelete(-1);
        setReload(!reload);
    };

    const tagIsActive = (index) => {
        return tagActivenesses[index];
    };

    const handleTagClick = (e, index) => {
        let newTagActivenesses = [...tagActivenesses];
        newTagActivenesses[index] = !newTagActivenesses[index];
        setTagActivenesses(newTagActivenesses);
    };

    // sort the cards by specified sort order
    function compare(a, b) {
        if (sortOrder === 0) {
            return 0;
        }
        if (sortOrder === 1) {
            return a.created_at > b.created_at ? -1 : 1;
        }
        if (sortOrder === 2) {
            return a.created_at < b.created_at ? -1 : 1;
        }
        if (sortOrder === 3) {
            return a.image_name < b.image_name ? -1 : 1;
        }
        if (sortOrder === 4) {
            return a.image_name > b.image_name ? -1 : 1;
        }
        return 0;
    }

    cards.sort(compare);
    processingCards.sort(compare);

    const shouldShowUp = (card) => {
        let cardTags = destructureTags(card.tags);
        let condition1 = // at least matches search query in one parameter
            card.description
                .toLowerCase()
                .includes(activeSearchQuery.toLowerCase()) ||
            card.image_name
                .toLowerCase()
                .includes(activeSearchQuery.toLowerCase()) ||
            card.created_at
                .toLowerCase()
                .includes(activeSearchQuery.toLowerCase());

        let condition2 = false; // one of the active tags is within this card's list of assigned tags
        let allCardsWereInactive = true;
        for (let i = 0; i < tags.length; i++) {
            if (tagIsActive(i)) {
                allCardsWereInactive = false;
                if (cardTags.includes(tags[i])) {
                    condition2 = true;
                }
            }
        }
        // unless, ofc, there are no active tags
        if (allCardsWereInactive) {
            condition2 = true;
        }

        return condition1 && condition2; // has to match search params AND be tagged with one of the active tags
    };

    const visitPage = (e, id) => {
        e.preventDefault();
        navigate(dynamizeURL(paths.VIEWER, org, id));
    };

    const visitPageInNewTab = (id) => {
        window.open(dynamizeURL(paths.VIEWER, org, id), "_blank");
    };

    return (
        <OrgWrapper>
            <OrgBar />
            <NavWrapper>
                <Navbar />
                <AllWrapper>
                    <Snackbar
                        open={showUploadAlert}
                        autoHideDuration={numbers.ALERT_TIMEOUT}
                    >
                        <Alert severity='info' sx={{ width: "100%" }}>
                            Processing Image Upload
                        </Alert>
                    </Snackbar>
                    <Modal
                        open={modalOpen}
                        onClose={handleClose}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                    >
                        <ModalContentWrapper>
                            <div>
                                <Typography
                                    id='modal-modal-title'
                                    variant='h6'
                                    component='h2'
                                >
                                    Are you sure you want to delete this image?
                                </Typography>
                                <Typography
                                    id='modal-modal-description'
                                    sx={{ mt: 2 }}
                                >
                                    This action cannot be undone!
                                </Typography>
                            </div>
                            <Button
                                size='small'
                                color='error'
                                variant='contained'
                                onClick={(e) => {
                                    deleteImage(imageToDelete);

                                    // delay reloading the page to allow the backend to delete the image
                                    setReload(!reload);
                                    setTimeout(() => {
                                        setReload(!reload);
                                    }, numbers.PING_DELAY);

                                    handleClose();
                                }}
                            >
                                <Delete />
                                &nbsp; Delete
                            </Button>
                        </ModalContentWrapper>
                    </Modal>
                    <Box maxWidth='md'>
                        <Container>
                            <Typography
                                component='h1'
                                variant={isMobile(width) ? "h3" : "h2"}
                                align='left'
                                color='text.primary'
                                gutterBottom
                            >
                                Browse {isMobile(width) ? "All" : "Images"}
                            </Typography>
                            {isMobile(width) ? (
                                <></>
                            ) : (
                                <Typography
                                    variant='h5'
                                    align='left'
                                    color='text.secondary'
                                    paragraph
                                >
                                    Search by name, date uploaded, or
                                    description.
                                </Typography>
                            )}
                        </Container>
                    </Box>
                    <ColumnHandler>
                        <MainColumn>
                            <ActionArea
                                sx={{
                                    bgcolor: "background.paper",
                                    pt: 8,
                                    pb: 6,
                                }}
                                maxWidth='100%'
                            >
                                <Container maxWidth='lg'>
                                    <TextField
                                        id='search-query'
                                        key='search-query'
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                setActiveSearchQuery(
                                                    searchQuery
                                                );
                                            }
                                        }}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'>
                                                    <Search />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <ClearQuery
                                                        onClick={() => {
                                                            setActiveSearchQuery(
                                                                ""
                                                            );
                                                            setSearchQuery("");
                                                        }}
                                                        edge='end'
                                                    >
                                                        <Close />
                                                    </ClearQuery>
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={searchQuery}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }}
                                        autoFocus
                                    />
                                </Container>
                                <EnterContainer>
                                    <EnterButton
                                        fullWidth
                                        size='large'
                                        variant='outlined'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setActiveSearchQuery(searchQuery);
                                        }}
                                    >
                                        Submit
                                    </EnterButton>
                                </EnterContainer>
                                {isMobile(width) ? (
                                    <></>
                                ) : (
                                    <OptionsContainer>
                                        <FormControl fullWidth>
                                            {/* <InputLabel>Sort Order</InputLabel> */}
                                            <Select
                                                value={sortOrder}
                                                onChange={(e) =>
                                                    setSortOrder(e.target.value)
                                                }
                                            >
                                                {/* <MenuItem value={0}>
                                                    Sort By
                                                </MenuItem> */}
                                                <MenuItem value={1}>
                                                    Recently Uploaded
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    First Uploaded
                                                </MenuItem>
                                                <MenuItem value={3}>
                                                    Name: A-Z
                                                </MenuItem>
                                                <MenuItem value={4}>
                                                    Name: Z-A
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </OptionsContainer>
                                )}
                            </ActionArea>
                            {isMobile(width) ? (
                                <TagStack>
                                    {tags.length === 0 ? (
                                        <></>
                                    ) : (
                                        tags.map((tag, index) => (
                                            <Chip
                                                color={
                                                    tagIsActive(index)
                                                        ? "primary"
                                                        : "default"
                                                }
                                                icon={
                                                    tagIsActive(index) ? (
                                                        <CheckCircle />
                                                    ) : (
                                                        <RadioButtonUnchecked />
                                                    )
                                                }
                                                key={index}
                                                label={tag}
                                                onClick={(e) =>
                                                    handleTagClick(e, index)
                                                }
                                                sx={{
                                                    width: "fit-content",
                                                }}
                                            />
                                        ))
                                    )}
                                </TagStack>
                            ) : (
                                <></>
                            )}
                            <Container
                                sx={{ py: 8, margin: "0", padding: "0" }}
                                maxWidth='lg'
                            >
                                <Grid container spacing={4}>
                                    {activeSearchQuery === "" ? (
                                        processingCards.map(
                                            (
                                                card,
                                                index // map through cards that are still processing // only name and description fields should be non-null
                                            ) => (
                                                <Grid
                                                    key={index}
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                >
                                                    <Card
                                                        sx={{
                                                            height: "fit-content",
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                        }}
                                                    >
                                                        <CardThumbnail
                                                            component='img'
                                                            key='search'
                                                            image={
                                                                urls.THUMBNAIL_FALLBACK
                                                            }
                                                            alt='random'
                                                        />
                                                        <LinearProgress />
                                                        <CardContent
                                                            sx={{ flexGrow: 1 }}
                                                        >
                                                            <Typography
                                                                gutterBottom
                                                                variant='h5'
                                                                component='h2'
                                                            >
                                                                <OneLine>
                                                                    {
                                                                        card.image_name
                                                                    }
                                                                </OneLine>
                                                            </Typography>
                                                            <CardStack>
                                                                {card.tags.map(
                                                                    (
                                                                        tag,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <Chip
                                                                                key={
                                                                                    tag.id +
                                                                                    index
                                                                                }
                                                                                label={
                                                                                    tag.name
                                                                                }
                                                                                sx={{
                                                                                    width: "fit-content",
                                                                                }}
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                            </CardStack>
                                                            <DateLine>
                                                                <OneLine>
                                                                    {getCurrentDate()}
                                                                </OneLine>
                                                            </DateLine>
                                                            <Typography>
                                                                <ShowMoreText
                                                                    lines={1}
                                                                    more='More'
                                                                    less='Less'
                                                                >
                                                                    {
                                                                        card.description
                                                                    }
                                                                </ShowMoreText>
                                                            </Typography>
                                                        </CardContent>
                                                        <ButtonRow>
                                                            <Button
                                                                size='small'
                                                                color='disabled'
                                                                disabled
                                                            >
                                                                <Visibility />{" "}
                                                                &nbsp; View
                                                            </Button>
                                                            <Button
                                                                size='small'
                                                                color='disabled'
                                                                disabled
                                                            >
                                                                <Delete />
                                                                &nbsp; Delete
                                                            </Button>
                                                        </ButtonRow>
                                                    </Card>
                                                </Grid>
                                            )
                                        )
                                    ) : (
                                        <></>
                                    )}
                                    {cards.map(
                                        (
                                            card,
                                            index // map through completed cards
                                        ) =>
                                            shouldShowUp(card) ? (
                                                <Grid
                                                    key={index}
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                >
                                                    <ContextMenu
                                                        options={[
                                                            {
                                                                label: "Open in New Tab",
                                                                onClick: (e) =>
                                                                    visitPageInNewTab(
                                                                        card.id
                                                                    ),
                                                            },
                                                        ]}
                                                        content={
                                                            <Card
                                                                sx={{
                                                                    height: "fit-content",
                                                                    display:
                                                                        "flex",
                                                                    flexDirection:
                                                                        "column",
                                                                }}
                                                            >
                                                                <CardThumbnail
                                                                    component='img'
                                                                    key='search'
                                                                    image={
                                                                        card.thumbnail_path
                                                                    }
                                                                    alt='random'
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        visitPage(
                                                                            e,
                                                                            card.id
                                                                        )
                                                                    }
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                                <CardContent
                                                                    sx={{
                                                                        flexGrow: 1,
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        gutterBottom
                                                                        variant='h5'
                                                                        component='h2'
                                                                    >
                                                                        <OneLine>
                                                                            {
                                                                                card.image_name
                                                                            }
                                                                        </OneLine>
                                                                    </Typography>
                                                                    <CardStack>
                                                                        {card.tags.map(
                                                                            (
                                                                                tag,
                                                                                index
                                                                            ) => {
                                                                                return (
                                                                                    <Chip
                                                                                        key={
                                                                                            tag.id +
                                                                                            index
                                                                                        }
                                                                                        label={
                                                                                            tag.name
                                                                                        }
                                                                                        sx={{
                                                                                            width: "fit-content",
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    </CardStack>
                                                                    <DateLine>
                                                                        <OneLine>
                                                                            {formatDate(
                                                                                card.created_at
                                                                            )}
                                                                        </OneLine>
                                                                    </DateLine>
                                                                    <Typography>
                                                                        <ShowMoreText
                                                                            lines={
                                                                                1
                                                                            }
                                                                            more='More'
                                                                            less='Less'
                                                                        >
                                                                            {
                                                                                card.description
                                                                            }
                                                                        </ShowMoreText>
                                                                    </Typography>
                                                                </CardContent>
                                                                <ButtonRow>
                                                                    <Button
                                                                        size='small'
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            visitPage(
                                                                                e,
                                                                                card.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <Visibility />{" "}
                                                                        &nbsp;
                                                                        View
                                                                    </Button>
                                                                    {admin &&
                                                                    !isMobile(
                                                                        width
                                                                    ) ? (
                                                                        <Button
                                                                            size='small'
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                handleOpen(
                                                                                    card.id
                                                                                );
                                                                            }}
                                                                        >
                                                                            <Delete />
                                                                            &nbsp;
                                                                            Delete
                                                                        </Button>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </ButtonRow>
                                                            </Card>
                                                        }
                                                    />
                                                </Grid>
                                            ) : (
                                                <></>
                                            )
                                    )}
                                </Grid>
                            </Container>
                        </MainColumn>
                        {isMobile(width) ? (
                            <></>
                        ) : (
                            <SideColumn>
                                <TagTitleArea>
                                    <Typography variant='h5' gutterBottom>
                                        Filter by Tags
                                    </Typography>
                                </TagTitleArea>
                                <TagStack>
                                    {tags.length === 0 ? (
                                        <NoTagsFound>No tags found</NoTagsFound>
                                    ) : (
                                        tags.map((tag, index) => (
                                            <Chip
                                                color={
                                                    tagIsActive(index)
                                                        ? "primary"
                                                        : "default"
                                                }
                                                icon={
                                                    tagIsActive(index) ? (
                                                        <CheckCircle />
                                                    ) : (
                                                        <RadioButtonUnchecked />
                                                    )
                                                }
                                                key={index}
                                                label={tag}
                                                onClick={(e) =>
                                                    handleTagClick(e, index)
                                                }
                                                sx={{
                                                    width: "fit-content",
                                                }}
                                            />
                                        ))
                                    )}
                                </TagStack>
                            </SideColumn>
                        )}
                    </ColumnHandler>
                </AllWrapper>
            </NavWrapper>
        </OrgWrapper>
    );
};

export default Browser;
