import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { paths } from "./Constants.js";
import { asyncAuth, refreshPage } from "./helperFuncs.js";
import LinearProgress from "@mui/material/LinearProgress";

const preserveSearchQuery = () =>
    window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "";

const Default = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation(); // pass on redirect state data if present

    useEffect(() => {
        asyncAuth()
            .then((authRes) => {
                console.log("redirecting to redirect hub...");
                console.log("state: ", state);
                refreshPage();
                navigate(
                    {
                        pathname: paths.REDIRECTHUB,
                        search: preserveSearchQuery(),
                    },
                    { state: state } ?? {}
                );
            })
            .catch((err) => {
                console.log("redirecting to login...");
                refreshPage();
                navigate(
                    {
                        pathname: paths.LOGIN,
                        search: preserveSearchQuery(),
                    },
                    { state: state } ?? {}
                );
            });
    });

    return <LinearProgress />;
};

export default Default;
