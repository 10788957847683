import { Box, Typography, CircularProgress } from "@mui/material";
import * as React from "react";
import { getAllSuperusers } from "./helperFuncs.js";
import UserList from "./list";
import OrgBar from "./organization";
import { AllWrapper, NavWrapper, OrgWrapper } from "./styles";

const GodView = () => {
    const [refresh, setRefresh] = React.useState(false);

    const [superusers, setSuperusers] = React.useState([]);

    React.useEffect(() => {
        getAllSuperusers()
            .then((superusers) => {
                setSuperusers(superusers);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [refresh]);

    return (
        <OrgWrapper>
            <OrgBar nonOrg />
            <NavWrapper>
                <AllWrapper>
                    <Box
                        sx={{
                            bgcolor: "background.paper",
                            pt: 8,
                            padding: "0 0 0 24px",
                        }}
                        maxWidth="100vw"
                    >
                        <Typography
                            component="h1"
                            variant="h2"
                            align="left"
                            color="text.primary"
                            gutterBottom
                        >
                            Superuser View
                        </Typography>
                        <Typography
                            variant="h5"
                            align="left"
                            color="text.secondary"
                            paragraph
                        >
                            Remove and Add Superusers
                        </Typography>
                        {superusers.length === 0 ? (
                            <CircularProgress />
                        ) : (
                            <UserList
                                arr={superusers}
                                refresh={() => setRefresh(!refresh)}
                            />
                        )}
                    </Box>
                </AllWrapper>
            </NavWrapper>
        </OrgWrapper>
    );
};

export default GodView;
