import { customVisuals, defaultTheme } from "./Constants";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React from "react";

/**
 * Attaches a little titlebar on top of a slideable window component overlaid on Openseadragon
 * @param {*} props should contain the following props:
 * @param {string} props.title - the title to display
 * @param {boolean} props.minimized - whether the window is minimized
 * @param {function} props.callback - callback to call when the titlebar is clicked
 * @param {React.Component} props.icon - the icon to display on the left
 * @param {boolean} fullWidth - whether the titlebar should be full width
 * @returns A React component that renders a titlebar
 */
const SeadragonToolbar = (props) => {
    return (
        <div
            style={{
                backgroundColor: defaultTheme.palette.primary.main,
                height: customVisuals.titleBarHeight + "px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "4px 24px 4px 24px",
                borderRadius: "5px 5px 0 0",
                color: "white",
                cursor: "pointer",
            }}
            onClick={() => {
                props.callback();
            }}
        >
            <span
                id="title"
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "16px",
                }}
            >
                {props.icon}
                <b>
                    {props.minimized
                        ? "Show " + props.title
                        : "Hide " + props.title}
                </b>
            </span>
            {props.minimized ? <ExpandLess /> : <ExpandMore />}
        </div>
    );
};

export default SeadragonToolbar;
