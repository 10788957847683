import { useContext, useEffect, useState } from "react";
import {
    useNavigate,
    useParams,
    useLocation,
    Navigate,
} from "react-router-dom";
import {
    LinearProgress,
    Box,
    Button,
    Stack,
    Typography,
    CircularProgress,
} from "@mui/material";
import { paths, dynamicPathAffixes } from "./Constants.js";
import {
    asyncAuth,
    dynamizeURL,
    isAdmin,
    listAllOrgs,
    logout,
    refreshPage,
    stripAllOrgsDownAsAdmin,
    orgContextIsValid,
} from "./helperFuncs.js";
import Navbar from "./Navbar.js";
import OrgBar, { OrgContext } from "./organization.js";
import { AllWrapper, NavWrapper, OrgWrapper } from "./styles.js";
import styled from "styled-components";
import useWindowDimensions, { isMobile } from "./windowDimensions.js";

const HiddenText = styled.p`
    display: none;
`;

const RedirectHub = () => {
    /** Modes:
     *  0: not init yet
     *  1: init as not superuser
     *  2: init as superuser
     */
    const [initialized, setInitialized] = useState(0);

    const { org, setOrg, allOrgs, setOrgs, user, setUser } =
        useContext(OrgContext);

    const { height, width } = useWindowDimensions();

    const { state } = useLocation();

    let navigate = useNavigate();

    useEffect(() => {
        asyncAuth()
            .then(async (authRes) => {
                console.log("authenticated! loading redirect hub...");

                // BEGIN USER/ORG INITIALIZATION ===============================

                setUser(authRes);

                // initialize orgs
                if (!orgContextIsValid(org, allOrgs, user)) {
                    // set current active org to last org in list
                    let activeOrgInd = 0;

                    // if no organizations already loaded, load from remote
                    if (authRes.is_superuser) {
                        // if superuser, load all orgs
                        listAllOrgs().then((allOrgs) => {
                            setOrgs(stripAllOrgsDownAsAdmin(allOrgs));

                            activeOrgInd = allOrgs.length - 1;
                        });

                        setInitialized(2);
                    } else {
                        if (authRes.organizations.length === 0) {
                            // if no orgs, let user know
                            alert(
                                "You are not a member of any organizations. Please contact an administrator."
                            );
                            logout();
                            navigate({
                                pathname: paths.DEFAULT,
                                search: queries.REFRESH_TRIGGER,
                            });
                        } else {
                            // load only this user's orgs
                            setOrgs(authRes.organizations);

                            activeOrgInd = authRes.organizations.length - 1;
                        }

                        setInitialized(1);
                    }

                    setOrg(activeOrgInd);
                }

                // END USER/ORG INITIALIZATION =================================
            })
            .catch((err) => {
                console.log("not authenticated! redirecting to login...");
                console.log(err);
                navigate(paths.LOGIN);
            });
    }, []);

    useEffect(() => {
        if (allOrgs.length !== 0) {
            if (state && state.goBackTo) {
                console.log("going back to ", state.goBackTo);
                navigate(state.goBackTo.substring(state.goBackTo.indexOf("/")));
            } else {
                console.log("going to load Dashboard");
                navigate(paths.DASHBOARD);
            }
        } else {
            console.log("waiting for allOrgs to load...");

            console.log(
                "also, performing a check to see if there truly are no orgs, in which case we should redirect to the superuser page"
            );

            console.log(
                "only performing said check, though, if we're a superuser -- otherwise it's irrel"
            );

            if (initialized === 2) {
                listAllOrgs().then((allOrgs) => {
                    if (allOrgs.length === 0) {
                        console.log(
                            "no orgs found -- at all! redirecting to superuser page..."
                        );
                        navigate(paths.GODVIEW);
                    }
                });
            }
        }
    }, [org, allOrgs, user, initialized]);

    return (
        <>
            <LinearProgress />
            <HiddenText>{JSON.stringify({ allOrgs, org, user })}</HiddenText>
        </>
    );
};

export default RedirectHub;
