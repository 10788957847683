import {
    Close,
    DeleteOutline,
    Edit,
    ExpandMore,
    Info,
    PlayCircle,
    Download,
} from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    Container,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import styled from "styled-components";
import {
    customVisuals,
    dynamicPathAffixes,
    maxCharLengthInValueCol,
    paths,
    queries,
} from "./Constants.js";
import { FatSeaDragon } from "./fatseadragon/index.js";
import {
    asyncAuth,
    calculateTagsDelta,
    deleteTag,
    destructureTags,
    formatDateAndTime,
    getTokenStrFromStorage,
    loadTags,
    orgContextIsValid,
    urlValidation,
} from "./helperFuncs.js";
import Navbar from "./Navbar.js";
import { OrgContext } from "./organization.js";
import SeadragonToolbar from "./seadragontoolbar.js";
import {
    infoDialogDesktopStyle,
    infoDialogMobileStyle,
    NavWrapper,
    OrgWrapper,
} from "./styles.js";
import useWindowDimensions, {
    isMobile,
    MOBILE_WIDTH,
} from "./windowDimensions.js";
import OrgBar from "./organization.js";
import { ModalBody, ModalFooter, ModalHeader, modalStyle } from "./styles.js";

require("dotenv").config();

const TagStack = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin: 25px 0 25px 0;
`;

const ViewerWrapper = styled.div``;

const AboutWrapper = styled.div`
    border-radius: 0 0 0 5px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.1);

    width: fit-content;
    margin: 0 0 0 0;
    background-color: rgba(255, 255, 255, ${customVisuals.opacity});
    z-index: 25;

    @media (min-width: ${MOBILE_WIDTH}px) {
        height: ${customVisuals.infoTool.height}px;
        padding: 12px 24px 12px 24px;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        height: 100%;
        width: 100%;
        padding: 24px 24px 24px 36px;
    }
`;

const AboutAdjCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    gap: 10px;
    padding: 0px 12px 0px 12px;
    margin: 0 0 0 0;
    width: fit-content;
    background-color: rgba(255, 255, 255, ${customVisuals.opacity});

    border-radius: 0 0 5px 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.1);
`;

const ColButton = styled(Button)`
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const InfoRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    line-height: 0px;
    flex-wrap: nowrap;
`;

const InfoBlob = styled.div`
    overflow: auto;
    overflow-wrap: break-word;
`;

const InfoScroller = styled.div`
    overflow-y: auto;
    overflow-x: auto;
    max-height: 640px;
    padding: 24px;
    width: 100%;
`;

const CloseRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    line-height: 0px;
    flex-wrap: nowrap;
`;

const ValueContent = styled.p``;

const getImage = async (id) => {
    return new Promise((resolve, reject) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var raw = "";

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL + "/api/v1/get_image?id=" + id,
            requestOptions
        )
            .then((response) => {
                let output = response.json();
                return output;
            })
            .then((result) => {
                resolve(result.image[0]);
            })
            .catch((error) => {
                resolve(null);
            });
    });
};

const recursivelyGenerateContent = (jsonObj) => {
    // jsonObj may have nested objects, so we need to recursively generate the content
    // The returned content will be a JSX element

    if (jsonObj === null) {
        return <>No metadata to display.</>;
    }

    // iterate through key-value pairs in jsonObj
    return (
        <>
            {Object.keys(jsonObj).map((key) => {
                return (
                    <>
                        {typeof jsonObj[key] !== "object" ? (
                            // if the value is a string, we can just return it
                            <>
                                {("" + jsonObj[key]).length >
                                maxCharLengthInValueCol ? (
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                        >
                                            <InfoRow>
                                                <b>{key}</b>
                                                <ValueContent>
                                                    {(
                                                        "" + jsonObj[key]
                                                    ).substring(
                                                        0,
                                                        maxCharLengthInValueCol
                                                    ) + "..."}
                                                </ValueContent>
                                            </InfoRow>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <InfoBlob>
                                                {"" + jsonObj[key]}
                                            </InfoBlob>
                                        </AccordionDetails>
                                    </Accordion>
                                ) : (
                                    <Accordion expanded={false}>
                                        <AccordionSummary>
                                            <InfoRow>
                                                <b>{key}</b>
                                                <ValueContent>
                                                    {"" + jsonObj[key]}
                                                </ValueContent>
                                            </InfoRow>
                                        </AccordionSummary>
                                    </Accordion>
                                )}
                            </>
                        ) : (
                            // if the value is an object, we need to recursively generate the content
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <i>{key}</i>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {recursivelyGenerateContent(jsonObj[key])}
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </>
                );
            })}
        </>
    );
};

const Viewer = (props) => {
    const { height, width } = useWindowDimensions();

    const { org, setOrg, allOrgs, setOrgs, user, setUser } =
        useContext(OrgContext);

    const params = useParams();
    const [image, setImage] = useState(null);

    const [reload, setReload] = useState(false);

    const [infoModalOpen, setInfoModalOpen] = useState(false);

    const [showPicInfo, setShowPicInfo] = useState(false);

    const [newImageName, setNewImageName] = useState("");
    const [newImageDescription, setNewImageDescription] = useState("");
    const [newImageTags, setNewImageTags] = useState([]); // in destructured format, for the currently selected tags
    const [allTags, setAllTags] = useState([]); // in destructured format, for all tags
    const [newTag, setNewTag] = useState("");

    const [editModalOpen, setEditModalOpen] = useState(false);
    const handleCloseEditModal = () => {
        setEditModalOpen(false);
        setReload(!reload);
    };
    const handleOpenEditModal = () => {
        setEditModalOpen(true);
    };
    const submitNameDescrChange = async (imageID, newName, newDescr) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());

        var formdata = new FormData();
        formdata.append("image_id", imageID);
        formdata.append("image_name", newName);
        formdata.append("description", newDescr);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        await fetch(
            process.env.REACT_APP_BACKEND_URL + "/api/v1/edit_description/",
            requestOptions
        )
            .then((response) => {
                let output = response.text();
                return output;
            })
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));
    };
    const submitTagsChange = async (imageID, tagsToAdd, tagsToRemove) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token " + getTokenStrFromStorage());
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            image_id: imageID,
            "tags added": tagsToAdd,
            "tags removed": tagsToRemove,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        await fetch(
            process.env.REACT_APP_BACKEND_URL + "/api/v1/edit_tags/",
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.log("error", error));
    };
    const handleEditModalSubmit = async () => {
        let tagChanges = calculateTagsDelta(
            destructureTags(image.tags),
            newImageTags
        );

        // Edit image name and description

        await submitNameDescrChange(
            image.id,
            newImageName,
            newImageDescription
        );

        // Edit tags for this image

        await submitTagsChange(
            image.id,
            tagChanges["tags added"],
            tagChanges["tags removed"]
        );

        // close the modal after 5 seconds
        handleCloseEditModal();
    };

    let navigate = useNavigate();
    useEffect(() => {
        asyncAuth()
            .then((authRes) => {
                console.log("authenticated! loading viewer page...");
            })
            .catch((err) => {
                console.log("not authenticated! redirecting to login page...");
                navigate(paths.LOGIN);
            });

        getImage(params[dynamicPathAffixes.imgID])
            .then((image) => {
                setImage(image);

                // also set the modal defaults to the current image
                setNewImageName(image.image_name);
                setNewImageDescription(image.description);
                setNewImageTags(destructureTags(image.tags));
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, [reload]);

    useEffect(() => {
        if (orgContextIsValid(allOrgs, org, user)) {
            try {
                loadTags(allOrgs[org].id).then((tags) => {
                    setAllTags(tags);
                });
            } catch (e) {
                console.log("error in viewer.js: ", e);
                navigate(
                    {
                        pathname: paths.DEFAULT,
                    },
                    { state: { goBackTo: window.location.href } }
                );
            }
        }
    }, [reload, allOrgs]);

    if (image === null) {
        return (
            <>
                <LinearProgress />
            </>
        );
    }

    return (
        <OrgWrapper>
            {isMobile(width) ? null : <OrgBar />}
            <NavWrapper>
                {isMobile(width) ? null : <Navbar />}
                <ViewerWrapper>
                    <Modal
                        open={infoModalOpen}
                        onClose={() => setInfoModalOpen(false)}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                    >
                        <Box sx={modalStyle}>
                            <ModalHeader>
                                <Typography
                                    id='modal-modal-title'
                                    variant='h6'
                                    component='h2'
                                >
                                    Advanced Image Metadata
                                </Typography>
                                <IconButton
                                    color='secondary'
                                    aria-label='Close Modal'
                                    onClick={() => setInfoModalOpen(false)}
                                >
                                    <Close />
                                </IconButton>
                            </ModalHeader>
                            <ModalBody>
                                <InfoScroller>
                                    {recursivelyGenerateContent(image.exif)}
                                </InfoScroller>
                            </ModalBody>
                        </Box>
                    </Modal>
                    <Modal
                        open={editModalOpen}
                        onClose={handleCloseEditModal}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                    >
                        <Box sx={modalStyle}>
                            <ModalHeader>
                                <Typography
                                    id='modal-modal-title'
                                    variant='h6'
                                    component='h2'
                                >
                                    Edit Name, Tags, and Description
                                </Typography>
                                <IconButton
                                    color='secondary'
                                    aria-label='Close Modal'
                                    onClick={handleCloseEditModal}
                                >
                                    <Close />
                                </IconButton>
                            </ModalHeader>
                            <ModalBody>
                                <TextField
                                    id='filled-basic'
                                    label='Image Name'
                                    variant='outlined'
                                    value={newImageName}
                                    onChange={(e) => {
                                        setNewImageName(e.target.value);
                                    }}
                                    autoFocus
                                />
                                <FormControl
                                    fullWidth
                                    sx={{
                                        margin: "16px 0 16px 0",
                                    }}
                                >
                                    <InputLabel id='demo-multiple-chip-label'>
                                        Tags
                                    </InputLabel>
                                    <Select
                                        labelId='demo-multiple-chip-label'
                                        id='demo-multiple-chip'
                                        multiple
                                        value={newImageTags}
                                        onChange={(e) =>
                                            setNewImageTags(e.target.value)
                                        }
                                        input={
                                            <OutlinedInput
                                                id='select-multiple-chip'
                                                label='Chip'
                                            />
                                        }
                                        renderValue={(selected) => {
                                            return (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexWrap: "wrap",
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value}
                                                            label={value}
                                                        />
                                                    ))}
                                                </Box>
                                            );
                                        }}
                                    >
                                        {allTags.map((tag) => (
                                            <MenuItem
                                                key={tag}
                                                value={tag}
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <Typography fullWidth>
                                                    {tag}
                                                </Typography>

                                                <Tooltip
                                                    title={
                                                        "Delete tag globally"
                                                    }
                                                    arrow
                                                    placement='right'
                                                >
                                                    <IconButton
                                                        aria-label='delete'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setNewImageTags(
                                                                newImageTags.filter(
                                                                    (t) =>
                                                                        t !==
                                                                        tag
                                                                )
                                                            );
                                                            setAllTags(
                                                                allTags.filter(
                                                                    (t) =>
                                                                        t !==
                                                                        tag
                                                                )
                                                            );
                                                            deleteTag(tag);
                                                        }}
                                                    >
                                                        <DeleteOutline fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </MenuItem>
                                        ))}
                                        <MenuItem>
                                            <TextField
                                                key='create-newtag'
                                                id='filled-basic'
                                                label='Create New Tag'
                                                variant='outlined'
                                                size='small'
                                                fullWidth
                                                value={newTag}
                                                onChange={(e) => {
                                                    setNewTag(e.target.value);
                                                }}
                                                onKeyDown={(e) => {
                                                    e.stopPropagation();
                                                    if (e.key === "Enter") {
                                                        setNewImageTags([
                                                            ...newImageTags,
                                                            newTag,
                                                        ]);
                                                        setAllTags([
                                                            ...allTags,
                                                            newTag,
                                                        ]);
                                                        setNewTag("");
                                                    }
                                                }}
                                                autoFocus
                                            />
                                            <IconButton
                                                aria-label='add'
                                                onClick={() => {
                                                    setNewImageTags([
                                                        ...newImageTags,
                                                        newTag,
                                                    ]);
                                                    setAllTags([
                                                        ...allTags,
                                                        newTag,
                                                    ]);
                                                    setNewTag("");
                                                }}
                                            >
                                                <PlayCircle
                                                    fontSize='large'
                                                    color='primary'
                                                />
                                            </IconButton>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    id='filled-textarea'
                                    label='Description'
                                    placeholder='Describe your image and make it easier to find later.'
                                    multiline
                                    variant='outlined'
                                    minRows={10}
                                    value={newImageDescription}
                                    onChange={(e) => {
                                        setNewImageDescription(e.target.value);
                                    }}
                                    autoFocus
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    variant='contained'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleEditModalSubmit();
                                    }}
                                >
                                    Submit
                                </Button>
                            </ModalFooter>
                        </Box>
                    </Modal>
                    <Container
                        component='main'
                        fullWidth
                        sx={{ padding: "0", margin: "0" }}
                    >
                        <div
                            style={
                                isMobile(width)
                                    ? infoDialogMobileStyle(showPicInfo)
                                    : infoDialogDesktopStyle(showPicInfo)
                            }
                        >
                            {isMobile(width) ? (
                                <></>
                            ) : (
                                <SeadragonToolbar
                                    title={"Image Information"}
                                    minimized={!showPicInfo}
                                    callback={() =>
                                        setShowPicInfo(!showPicInfo)
                                    }
                                    icon={<Info />}
                                />
                            )}
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    height: "100%",
                                }}
                            >
                                <AboutWrapper>
                                    {isMobile(width) ? (
                                        <CloseRow>
                                            <IconButton
                                                aria-label='close'
                                                onClick={() =>
                                                    setShowPicInfo(false)
                                                }
                                            >
                                                <Close />
                                            </IconButton>
                                        </CloseRow>
                                    ) : (
                                        <></>
                                    )}
                                    <Typography
                                        component='h2'
                                        variant='h4'
                                        align='left'
                                        color='text.primary'
                                        gutterBottom
                                        sx={{ marginTop: "15px" }}
                                    >
                                        {image.image_name}
                                    </Typography>
                                    {destructureTags(image.tags).length > 0 ? (
                                        <TagStack>
                                            {destructureTags(image.tags).map(
                                                (tag) => {
                                                    return (
                                                        <Chip
                                                            key={tag}
                                                            label={tag}
                                                            sx={{
                                                                width: "fit-content",
                                                            }}
                                                        />
                                                    );
                                                }
                                            )}
                                        </TagStack>
                                    ) : (
                                        <></>
                                    )}
                                    <Typography
                                        variant='h6'
                                        align='left'
                                        color='text.secondary'
                                        paragraph
                                    >
                                        {"Uploaded " +
                                            formatDateAndTime(image.created_at)}
                                    </Typography>

                                    {image.description.length > 0 ? (
                                        <>
                                            <b>Description:</b>
                                            <Typography
                                                variant='p'
                                                align='left'
                                                color='text.primary'
                                                paragraph
                                            >
                                                <ShowMoreText
                                                    lines={1}
                                                    more='Expand'
                                                    less='Less'
                                                >
                                                    {image.description}
                                                </ShowMoreText>
                                            </Typography>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    {image.file_path &&
                                    !image.file_path.startsWith("archive/") ? (
                                        <Button
                                            variant='outlined'
                                            size='small'
                                            onClick={() => {
                                                // TODO: implement download
                                                window
                                                    .open(
                                                        image.file_path,
                                                        "_blank"
                                                    )
                                                    .focus();
                                            }}
                                            endIcon={<Download />}
                                        >
                                            Download Original
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </AboutWrapper>
                                {isMobile(width) ? (
                                    <></>
                                ) : (
                                    <AboutAdjCol>
                                        <ColButton
                                            variant='text'
                                            onClick={handleOpenEditModal}
                                        >
                                            <Edit />
                                            Edit
                                        </ColButton>
                                        <ColButton
                                            variant='text'
                                            onClick={(e) =>
                                                setInfoModalOpen(true)
                                            }
                                        >
                                            <Info />
                                            Meta
                                        </ColButton>
                                    </AboutAdjCol>
                                )}
                            </div>
                        </div>
                        <br />
                        <FatSeaDragon
                            prefixUrl={image.tile_path}
                            tileSources={image.manifest_path}
                            showNavigator={false}
                            imageId={params[dynamicPathAffixes.imgID]}
                            orgId={org} // params[dynamicPathAffixes.orgID] ??
                            token={getTokenStrFromStorage()}
                            colorPresets={image.color_presets}
                            // below is for the child component to handle the visibility of info panels in this page
                            showPicInfo={showPicInfo}
                            setShowPicInfo={setShowPicInfo}
                        />
                    </Container>
                </ViewerWrapper>
            </NavWrapper>
        </OrgWrapper>
    );
};

export default Viewer;
