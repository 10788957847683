import {
    AddBox,
    Close,
    Logout,
    ManageAccounts,
    Settings,
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    IconButton,
    Modal,
    TextField,
    Tooltip,
    Typography,
    CircularProgress,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import {
    Navigate,
    useNavigate,
    useParams,
    useLocation,
} from "react-router-dom";
import styled from "styled-components";
import {
    customVisuals,
    defaultTheme,
    emptyUser,
    paths,
    queries,
    dynamicPathAffixes,
} from "./Constants";
import {
    createOrg,
    dynamizeURL,
    getIconPic,
    logout,
    orgContextIsValid,
} from "./helperFuncs";
import { ModalBody, ModalFooter, ModalHeader, modalStyle } from "./styles.js";
import useWindowDimensions, {
    isMobile,
    MOBILE_WIDTH,
} from "./windowDimensions.js";

// set the defaults
const OrgContext = React.createContext({
    allOrgs: [],
    org: 0, // the index of the currently active org in allOrgs - NOT ITS ID
    user: emptyUser,
    setOrgs: () => {},
    setOrg: () => {},
    setUser: () => {},
});

export { OrgContext };

const OrgBar = (props) => {
    const { height, width } = useWindowDimensions();

    let navigate = useNavigate();

    const { state } = useLocation();

    const {
        org: org,
        setOrg: setOrg,
        allOrgs: allOrgs,
        setOrgs: setOrgs,
        user: user,
        setUser: setUser,
    } = useContext(OrgContext);

    const [activeStates, setActiveStates] = useState([]);

    useEffect(() => {
        let newActiveStates = Array(allOrgs.length).fill(false);
        newActiveStates[org] = true;
        setActiveStates(newActiveStates);
    }, [org, allOrgs]);

    const [createOrgModal, setCreateOrgModal] = useState(false);
    const [createOrgName, setCreateOrgName] = useState("");
    const handleCreateOrgModalClose = () => {
        setCreateOrgModal(false);
        setCreateOrgName("");
    };
    const handleCreateOrgModalOpen = () => {
        setCreateOrgModal(true);
    };
    const handleSubmitCreateOrg = () => {
        if (createOrgName.length > 0) {
            createOrg(createOrgName, "")
                .then((res) => {
                    handleCreateOrgModalClose();
                    navigate({
                        pathname: paths.DEFAULT,
                        search: queries.REFRESH_TRIGGER,
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            alert("Please enter a name for the organization!");
        }
    };

    const handleClick = (index) => {
        setOrg(index);
        navigate(dynamizeURL(paths.DASHBOARD, index));
    };

    const VerticalBar = styled.div`
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: ${defaultTheme.palette.primary.main};
        color: white;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
        padding: 12px;
        z-index: 1;
        @media (min-width: ${MOBILE_WIDTH}px) {
            flex-direction: column;
            width: ${customVisuals.sidebarWidth}px;
            height: 100vh;
        }
        @media (max-width: ${MOBILE_WIDTH}px) {
            flex-direction: row;
            width: 100%;
            height: ${customVisuals.sidebarWidth}px;
            ${props.nonOrg ? "top: 0" : "bottom: 0"}
            overflow: auto;
        }
    `;

    const OrgOptions = styled.div`
        display: flex;
        gap: 8px;
        overflow-y: auto;

        // hide scrollbar on chrome
        ::-webkit-scrollbar {
            width: 0px;
        }

        @media (min-width: ${MOBILE_WIDTH}px) {
            margin-top: 8px;
            flex-direction: ${user.is_superuser ? "column-reverse" : "column"};
        }
        @media (max-width: ${MOBILE_WIDTH}px) {
            flex-direction: row;
        }
    `;

    const AccountTools = styled.div`
        display: flex;
        flex-direction: column;

        @media (min-width: ${MOBILE_WIDTH}px) {
            flex-direction: column;
        }
        @media (max-width: ${MOBILE_WIDTH}px) {
            gap: 24px;
            flex-direction: row;
        }
    `;

    const ToolStyle = {
        backgroundColor: "transparent",
        padding: "12px 0",
        margin: "6px 0",
        color: "white",
    };

    useEffect(() => {
        // if no organizations already loaded, send user back to default page to load that data
        if (!orgContextIsValid(org, allOrgs, user)) {
            console.log("no orgs loaded, sending back to default page");
            navigate(
                {
                    pathname: paths.DEFAULT,
                },
                { state: { goBackTo: window.location.href } }
            );
        }
    }, [allOrgs, user]);

    useEffect(() => {
        if (state && state.goBackTo) {
            navigate(state.goBackTo.substring(state.goBackTo.indexOf("/")));
        }

        // if org is set (and the URL needs fixing), update url
        if (
            org !== null &&
            window.location.href.includes(dynamicPathAffixes.orgID)
        ) {
            window.history.replaceState(
                null,
                document.title,
                dynamizeURL(window.location.pathname, org)
            );
        }
    }, [window.location.href]);

    if (!orgContextIsValid(org, allOrgs, user)) {
        return (
            <>
                <CircularProgress />
            </>
        );
    } else {
        return (
            <>
                <Modal
                    open={createOrgModal}
                    onClose={handleCreateOrgModalClose}
                >
                    <Box sx={modalStyle}>
                        <ModalHeader>
                            <Typography
                                id='modal-modal-title'
                                variant='h6'
                                component='h2'
                            >
                                Enter the name of your new organization
                            </Typography>
                            <IconButton
                                color='secondary'
                                aria-label='Close Modal'
                                onClick={handleCreateOrgModalClose}
                            >
                                <Close />
                            </IconButton>
                        </ModalHeader>
                        <ModalBody>
                            <TextField
                                id='standard-basic'
                                // label="Name"
                                variant='standard'
                                value={createOrgName}
                                onChange={(e) =>
                                    setCreateOrgName(e.target.value)
                                }
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        handleSubmitCreateOrg();
                                    }
                                }}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                variant='contained'
                                onClick={handleSubmitCreateOrg}
                            >
                                Submit
                            </Button>
                        </ModalFooter>
                    </Box>
                </Modal>
                <VerticalBar>
                    <OrgOptions>
                        {allOrgs.map((org, index) => {
                            return (
                                <Tooltip
                                    title={org.name}
                                    arrow
                                    placement='right'
                                >
                                    <Button
                                        onClick={() => {
                                            handleClick(index);
                                        }}
                                        sx={
                                            props.nonOrg
                                                ? {
                                                      backgroundColor:
                                                          "transparent",
                                                      padding: "12px 0",
                                                      borderRadius: "12px",
                                                      border: "3px solid transparent",
                                                  }
                                                : {
                                                      backgroundColor:
                                                          "transparent",
                                                      padding: "12px 0",
                                                      borderRadius: "12px",
                                                      border: activeStates[
                                                          index
                                                      ]
                                                          ? "3px solid white"
                                                          : "3px solid transparent",
                                                  }
                                        }
                                    >
                                        <Avatar
                                            alt='Avatar'
                                            src={getIconPic(org.name)}
                                            variant='rounded'
                                            sx={{
                                                width: "36px",
                                                height: "36px",
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            );
                        })}
                    </OrgOptions>
                    <AccountTools>
                        {user.is_superuser && !isMobile(width) ? (
                            <>
                                <Tooltip
                                    title={"Create Organization"}
                                    arrow
                                    placement='right'
                                >
                                    <IconButton
                                        onClick={handleCreateOrgModalOpen}
                                        sx={ToolStyle}
                                    >
                                        <AddBox />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title={"Manage Superusers"}
                                    arrow
                                    placement='right'
                                >
                                    <IconButton
                                        onClick={() => {
                                            navigate(paths.GODVIEW);
                                        }}
                                        sx={ToolStyle}
                                    >
                                        <ManageAccounts />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : (
                            <CircularProgress />
                        )}
                        <Tooltip
                            title={"Account Settings"}
                            arrow
                            placement='right'
                        >
                            <IconButton
                                onClick={() => {
                                    navigate(paths.ACCOUNT);
                                }}
                                sx={ToolStyle}
                            >
                                <Settings />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Logout"} arrow placement='right'>
                            <IconButton
                                onClick={() => {
                                    logout();
                                    navigate({
                                        pathname: paths.DEFAULT,
                                        search: queries.REFRESH_TRIGGER,
                                    });
                                }}
                                sx={ToolStyle}
                            >
                                <Logout />
                            </IconButton>
                        </Tooltip>
                    </AccountTools>
                </VerticalBar>
            </>
        );
    }
};

export default OrgBar;
