import { useEffect, useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
    Alert,
    Avatar,
    Box,
    Button,
    Checkbox,
    CssBaseline,
    FormControlLabel,
    Grid,
    Link,
    Paper,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Copyright from "./copyright";
import { numbers, paths, queries, urls } from "./Constants.js";
import { asyncAuth, removeQueryParams } from "./helperFuncs.js";

require("dotenv").config();

const Login = () => {
    let navigate = useNavigate();
    useEffect(() => {
        asyncAuth()
            .then((authRes) => {
                if (authRes.first_name) {
                    console.log(
                        "already authenticated! redirecting to dashboard..."
                    );


                    navigate(paths.BROWSER);
                }
            })
            .catch((err) => {
                console.log("not authenticated! staying on login...");
            });
    }, []);

    // tomeko: will force this to true (local storage), logout should clear local storage
    //const [remember, setRemember] = useState(false);

    const showLoginAlerts = window.location.href.includes(
        queries.SIGNUP_SUCCESS
    );
    if (showLoginAlerts) {
        setTimeout(function () {
            removeQueryParams();
        }, numbers.ALERT_TIMEOUT); // remove the alert after n seconds
    }

    const [showFailed, setShowFailed] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const toSend = {
            email: data.get("email"),
            password: data.get("password"), //TODO: encrypt password
        };

        // if remember is true, save the email and password in local storage/cookie somehow and validate future logins
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            username_or_email: toSend.email,
            password: toSend.password,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            process.env.REACT_APP_BACKEND_URL + "/auth/login/",
            requestOptions
        )
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    setShowFailed(true);
                    setTimeout(function () {
                        setShowFailed(false);
                    }, numbers.ALERT_TIMEOUT); // remove the alert after 6 seconds
                }
            })
            .then((data) => {
                localStorage.setItem("token", data.token);
                
                navigate(paths.BROWSER);
            })
            .catch((error) => console.log("error", error));
    };

    return (
        <>
            <Snackbar
                open={showLoginAlerts}
                autoHideDuration={numbers.ALERT_TIMEOUT}
            >
                <Alert severity='success' sx={{ width: "100%" }}>
                    Account Created Successfully! Sign In to Continue
                </Alert>
            </Snackbar>
            <Snackbar
                open={showFailed}
                autoHideDuration={numbers.ALERT_TIMEOUT}
            >
                <Alert severity='error' sx={{ width: "100%" }}>
                    Invalid Login
                </Alert>
            </Snackbar>
            <Grid container component='main' sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${urls.LOGIN_BG})`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                            t.palette.mode === "light"
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                />
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component='h1' variant='h5'>
                            Sign in
                        </Typography>
                        <Box
                            component='form'
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 1 }}
                        >
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                id='email'
                                label='Email Address'
                                name='email'
                                autoComplete='email'
                                autoFocus
                                data-testid='Email'
                            />
                            <TextField
                                margin='normal'
                                required
                                fullWidth
                                name='password'
                                label='Password'
                                type='password'
                                id='password'
                                autoComplete='current-password'
                                data-testid='Password'
                            />
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href='#' variant='body2'>
                                        {/* Forgot password? TODO: IMPLEMENT THIS */}
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link
                                        onClick={() => navigate(paths.SIGNUP)}
                                        variant='body2'
                                    >
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Login;
