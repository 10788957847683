import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

/**
 * Surrounds the child content with a context menu on right click.
 * @param props.content - content surrounded by the right-click context menu of type JSX.Element
 * @param props.options - array of options for the context menu of type Array<{label: string, onClick: () => void}>
 * @returns {JSX.Element}
 */
export default function ContextMenu(props) {
    const [contextMenu, setContextMenu] = React.useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX + 2,
                      mouseY: event.clientY - 6,
                  }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    return (
        <div
            onContextMenu={handleContextMenu}
            style={{ cursor: "context-menu" }}
        >
            {props.content}
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference='anchorPosition'
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                {props.options.map((option, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            handleClose();
                            option.onClick();
                        }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
